import { Component, OnDestroy } from '@angular/core';
import { SubscriptionBarrel } from './bodyanalytics-subscription-barrel';

@Component({
  template: '',
})
export class BodyanalyticsBaseComponent implements OnDestroy {
  /**
   * @description The subscription sink object that stores all subscriptions
   */
  protected subscriptions = new SubscriptionBarrel();

  /**
   * @description Sets the unique identifier for an array item of type `T`
   * @param index The index of the `item` in the array
   * @param item The element in the array of type `T`
   * @returns number
   */
  protected trackBy<T>(index: number, item: T): number {
    return index;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public transformToTitleCase(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters in camelCase
               .replace(/_/g, ' ') // Replace underscores with spaces
               .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  }
}
