import { ProviderI, TimeSlotI } from './testing-events';

export interface ServicesI {
  // duration: number;
  id: string; // id: number;
  selected: boolean;
  isChecked: boolean;
  name: string;
  description: string;
  price: number;
  serviceFee: number;
  quantity?: number; //not needed
  num?: number; //not needed
  testingEventId?: string; // not needed
  categoryId: string; //  categoryId: number; // Assuming each service is linked to a category
  availableSlots: TimeSlotI[];
  providersAvailable: ProviderI[];
  // providers: string[]; // List of professionals or providers
  rating: number; // Average rating of the service
  imageUrl: string; // URL of an image representing the service
  duration: number; // Duration of the service in minutes
  specialInstructions: string; // Any special instructions or notes about the service
}
