import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'bodyanalytics-logout-button',
  templateUrl: './logout-button.component.html',
})
export class LogoutButtonComponent {
  protected auth = inject(AuthService);
  protected location = inject(Location);

  logout() {
    this.auth.logout({ openUrl: url => location.replace(url) });
  }
}
