export enum TestingSessionStep {
  SETUP = 'Setup',
  CUSTOMER = 'Customer',
  TEST = 'Test',
  REVIEW = 'Review',
  COMPLETE = 'Complete',
}
export enum TestingSessionStatus {
  GOOD = 'Good',
  OK = 'OK'
}
