import { DOCUMENT } from '@angular/common';
import { Component, Inject,   } from '@angular/core';
import { Router } from '@angular/router';
 import { ENV } from '@bodyanalytics/app-config';

@Component({
  selector: 'bodyanalytics-calendar-button',
  templateUrl: './calendar-button.component.html',
  styleUrls: ['./calendar-button.component.scss'],
})
export class CalendarButtonComponent {
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) private appConfig: any,
  ) {}
  calendar() {
    this.document.location.href = `${this.appConfig.calendarUrl}`;
  }
}
