import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaderEnum } from '@bodyanalytics/ui-core';
import { EMPTY, Observable, catchError, map, throwError } from 'rxjs';
import { AbxErrorResponse, AbxHttpErrorResponse } from './interceptors';
import { NavigationHubService } from '../navigation-hub/navigation-hub.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  // private spinnerData: SpinnerData;
  constructor(
    private authService: AuthService,
    private navHubService: NavigationHubService,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.initiateAbxSpinner(req);
    // req = this.finializeRequestHeader(req);
    console.log('request: ', req);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event: ', event);
          this.stopSpinner();
        }
        return event;
      }),
      catchError((failure: AbxHttpErrorResponse) => {
        console.log('error: ', failure.status);
        if (this.isAbxApiUnauthorizedError(failure.status, req)) {
          this.navHubService.routeToLogin();
          return EMPTY;
          // if(this.authService.modeAuthToken?.refreshToken){
          //   return this.handle401Error(req, next);
          // } else {
          //   this.navHubService.routeToLogin();
          //   return EMPTY
          // }
        } else {
          this.stopSpinner();
          return throwError(new AbxErrorResponse(failure));
        }
      }),
    );
  }
  private isAbxApiUnauthorizedError(errorStatus: number, request: HttpRequest<any>): boolean {
    console.log('request.url: ' + request.url);
    return errorStatus === 401;
  }
  private reqHasHeader(request: HttpRequest<any>, headerEnum: HttpHeaderEnum): boolean {
    return request.headers.has(headerEnum);
  }
  private stopSpinner(): void {
    console.log('stop spinner');
    // if (this.spinnerData) {
    //   this.spinnerService.stop();
    //   this.spinnerData = null;
    // }
  }
  private initiateAbxSpinner(request: HttpRequest<any>): HttpRequest<any> {
    console.log('start spinner');
    return request;
  }
}
