export interface ProfileDetailsI {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  age?: string;
  ethnicity: string;
  race: string;
  gender: string;
  dob: string;
  zipCode: boolean;
  homeGym: string;
  trainer: string;
  bio: string;
}
