import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { RegisterService } from '../../services/register.service';

@Component({
  selector: 'bodyanalytics-register-profile',
  templateUrl: './register-profile.component.html',
  styleUrls: ['./register-profile.component.scss'],
})
export class RegisterProfileComponent extends BodyanalyticsBaseComponent {
  constructor(private router: Router, private registerService: RegisterService) {
    super();
  }
  update(login: any) {
    this.router.navigate([`${RouterUrlsEnum.AUTH_DASHBOARD}`]);
    this.subscriptions.barrel = this.registerService.createProfile(login).subscribe();
  }
}
