import { signalStoreFeature, withMethods, patchState, withState, withHooks } from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, Observable } from 'rxjs';
import { ProviderToken, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';

export const withEntitiesStore = <Entity extends { id: string }>(
  collection: string,
  Service: ProviderToken<{ load: () => Observable<Entity[]> }>,
) =>
  signalStoreFeature(
    withState({ loading: true }),
    withEntities<Entity>(),
    withMethods((s, loader = inject(Service)) => ({
      load: rxMethod<void>(
        pipe(
          switchMap(
            () => (
              patchState(s, { loading: true }),
              loader.load().pipe(
                tapResponse({
                  next: entities => patchState(s, setAllEntities(entities)),
                  error: error => console.error(error),
                  finalize: () => patchState(s, { loading: false }),
                }),
              )
            ),
          ),
        ),
      ),
    })),
    withHooks({
      onInit: s => s.load(),
    }),
  );
