import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BodyFatReportI, BodyFatTestI, GoalI, ClientHistory, BodyFatTestReportI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDashboardService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  #env = inject(ENV);
  private testingUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS;
  private bodyMeasurementsComplete = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_COMPLETE;
  private bodyFatTestsUrl = this.testingUrl;
  private bodyFatTestsDetailsUrl = this.testingUrl; // URL to web api
  private goalsUrl = `${this.testingUrl}/goals-api`; // URL to web api

  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  public findAllCompletedTestReport(): Observable<BodyFatTestReportI[]> {
    const reportId = 'reportId';

    return this.http.get<BodyFatTestReportI[]>(`${this.#env.api}/${this.bodyMeasurementsComplete}`).pipe(
      tap(_ => this.sharedLogService.log(`fetched reportId id=${reportId}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestReportI[]>(`findTestReport id=${reportId}`)),
    );
  }

  loadClientHistory() {
    return this.http
      .get<ClientHistory[]>(`${this.#env.api}/manage-bodymeasurements-api/test-session/client-history`)
      .pipe(
        tap(() => this.sharedLogService.log(`fetched client history`)),
        catchError(this.sharedErrorService.handleError<ClientHistory[]>('loadClientHistory', [])),
      );
  }

  public findCompeledTestReport(reportId: string): Observable<BodyFatReportI> {
    const url = `${this.#env.api}/${this.bodyMeasurementsComplete}/${reportId}`;
    return this.http.get<BodyFatReportI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched reportId id=${reportId}`)),
      catchError(this.sharedErrorService.handleError<BodyFatReportI>(`findTestReport id=${reportId}`)),
    );
  }

  getManageTest(id: string): Observable<BodyFatTestI> {
    const url = `${this.#env.api}/${this.bodyFatTestsDetailsUrl}/${id}`;
    return this.http.get<BodyFatTestI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched bodyFatTests id=${id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>(`getManageTest id=${id}`)),
    );
  }

  updateManageTest(id: string, bodyFatTests: BodyFatTestI): Observable<BodyFatTestI> {
    const url = `${this.#env.api}/${this.bodyFatTestsDetailsUrl}/${id}`;
    return this.http.patch(url, bodyFatTests, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated bodyFatTests id=${id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('updateManageTest')),
    );
  }

  deleteManageTest(id: string): Observable<BodyFatTestI> {
    const url = `${this.#env.api}/${this.bodyFatTestsUrl}/${id}`;

    return this.http.delete<BodyFatTestI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted bodyFatTests id=${id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('deleteManageTest')),
    );
  }

  getManageTests(): Observable<BodyFatTestI[]> {
    const url = `${this.#env.api}/${this.bodyFatTestsUrl}`;
    return this.http.get<BodyFatTestI[]>(url).pipe(
      tap(_ => console.log('fetched tests')),
      catchError(this.sharedErrorService.handleError<BodyFatTestI[]>('getManageTests', [])),
    );
  }

  addManageTest(bodyFatTests: BodyFatTestI): Observable<BodyFatTestI> {
    const url = `${this.#env.api}/${this.bodyFatTestsUrl}`;

    return this.http.post<BodyFatTestI>(url, bodyFatTests, this.httpOptions).pipe(
      tap((newManageTest: BodyFatTestI) => this.sharedLogService.log(`added bodyFatTests w/ id=${newManageTest.id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('addManageTest')),
    );
  }

  createGoal(goalData: GoalI): Observable<GoalI> {
    const url = `${this.#env.api}/${this.goalsUrl}`;

    return this.http.post<GoalI>(url, goalData, this.httpOptions).pipe(
      tap((newGoal: GoalI) => this.sharedLogService.log(`added goal w/ id=${newGoal.id}`)),
      catchError(this.sharedErrorService.handleError<GoalI>('create goal')),
    );
  }

  searchManageTests(term: string): Observable<BodyFatTestI[]> {
    const url = `${this.#env.api}/${this.bodyFatTestsUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty bodyFatTests array.
      return of([]);
    }

    return this.http.get<BodyFatTestI[]>(`${url}`).pipe(
      tap(x => (x.length ? console.log(`found tests matching "${term}"`) : console.log(`no tests matching "${term}"`))),
      catchError(this.sharedErrorService.handleError<BodyFatTestI[]>('searchManageTests', [])),
    );
  }

  downloadLatestResult() {
    return this.http
      .get(`${this.#env.api}/manage-bodymeasurements-api/download/pdf-report`, { responseType: 'arraybuffer' })
      .pipe(
        tap(() => this.sharedLogService.log(`downloaded latest result`)),
        catchError(this.sharedErrorService.handleError('downloadLatestResult')),
      );
  }
}
