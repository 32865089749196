import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'bodyanalytics-timer',
  template: `
    <div *ngIf="showAlert" class="alert alert-warning" role="alert">
      <p>Time remaining: {{ minutes }}:{{ seconds | number : '2.0' }}</p>
    </div>
  `,
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit, OnDestroy {
  minutes: number = 5;
  seconds: number = 0;
  private intervalId: any;
  showAlert: boolean = true;

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startTimer(): void {
    this.intervalId = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.showAlert = false;
          clearInterval(this.intervalId);
          alert('Time is up!');
        } else {
          this.minutes--;
          this.seconds = 59;
        }
      } else {
        this.seconds--;
      }
    }, 1000);
  }
}
