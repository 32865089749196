import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EthnicityI, GenderI, RacesI } from '@bodyanalytics/data-models-ui';
import { ethnicityData, genderData, raceData } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-register-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class RegisterProfileFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();

  readonly ethnicityList: EthnicityI[] = [];
  readonly genderList: GenderI[] = [];
  readonly raceList: RacesI[] = [];

  formsubmit = false;

  constructor() {
    this.ethnicityList = ethnicityData;
    this.genderList = genderData;
    this.raceList = raceData;
  }

  public securityForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
    lastName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
    emailAddress: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
    ]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]{11}')]),
    gender: new FormControl('', [Validators.required]),
    ethnicity: new FormControl('', [Validators.required]),
    race: new FormControl('', [Validators.required]),
    birthDate: new FormControl('', [Validators.required]),
  });

  public createProfile(): void {
    this.submitFormEvent.emit({
      firstName: this.securityForm.value.firstName as string,
      lastName: this.securityForm.value.lastName as string,
      gender: this.securityForm.value.gender as string,
      ethnicity: this.securityForm.value.ethnicity as string,
      race: this.securityForm.value.race as string,
      birthDate: this.securityForm.value.birthDate as string,
      phoneNumber: this.securityForm.value.phoneNumber as string,
      emailAddress: this.securityForm.value.emailAddress as string,
      // newPassword: this.securityForm.value.newPassword as string,
      // confirmPassword: this.securityForm.value.confirmPassword as string,
    });
  }
  /**
   * Returns form
   */
  get form() {
    return this.securityForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
