import { Env } from '@bodyanalytics/app-config';

export const environment: Env = {
  production: false,
  api: 'https://dev.backendapi.appliedbiox.com/api/v1',
  auth0: {
    domain: 'dev-k81cxnrfzle2ohvb.us.auth0.com',
    clientId: '24NRkSlgEdmKa8drcHy9LKuh4IUbdxY4',
    authorizationParams: {
      audience: 'http://abx-api-admin.com',
      redirect_uri: 'https://dev.customer.appliedbiox.com',
    },
    errorPath: '/error',
  },
  calendarUrl: 'https://dev.calendar.appliedbiox.com',
  stripePKey: 'pk_test_51LcHxNCSE2tS1SEMSVHckkw4rFpfAtUNwTXDp4vW8WSu23egXYuhecUqFqMelJ1FICxyzvVbQ2mR2u8YrpQdlkgO00Scu96jdl',
  portalName: 'customer',
  analyticsMeasurementId: 'G-1J8919V5Z3',
  stripe_package_price_1: 'original-hydro-price-per-test',
  stripe_package_price_2: 'price_1Lq0j3CSE2tS1SEMscdRcuzb',
  stripe_package_price_3: 'price_1PgzgBCSE2tS1SEMZcrayWuL',
  stripe_package_price_4: 'price_1PgzpCCSE2tS1SEMZ6yYKvy8',
  stripe_package_price_5: 'price_1Pgzs9CSE2tS1SEMbHXk1sb9',
  stripe_package_price_6: 'price_1PgzsrCSE2tS1SEMSHkpydxD',
  stripe_package_price_7: 'price_1PgzqcCSE2tS1SEMCL7w9xlJ',
};
