import { HttpErrorResponse } from '@angular/common/http';

export interface AbxHttpErrorResponse extends HttpErrorResponse {
  error: AbxApiError;
}

export interface AbxApiError {
  errorCode: string;
  errorMessage: string;
  errorSummary: string;
}

export enum ServerCode {
  SUCCESS = 200,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  UNRESOLVABLE = 502,
}

export class AbxErrorResponse {
  constructor(errorObj: AbxHttpErrorResponse) {
    this.status = errorObj.status;
    this.message = errorObj.message;
    this.url = errorObj.url as any;
    this.apiErrorCode = errorObj.error.errorCode;
    this.apiErrorMsg = errorObj.error.errorMessage
      ? errorObj.error.errorMessage
      : errorObj.error.errorSummary;
  }

  public status: number;
  public message: string;
  public url: string;
  public apiErrorCode: string;
  public apiErrorMsg: string;

  public static apiErrorToContentMsgMapper(error: AbxErrorResponse): string {
    return error.apiErrorCode
    // switch (error.apiErrorCode) {
    //   case 'E0000001':
    //     return ContentEnum.REGISTER_USER_ALREADY_EXIST;
    //   case 'E0000004':
    //     return ContentEnum.LOGIN_INVALID_CREDENTIALS;
    //   case 'E0000068':
    //     return ContentEnum.MFA_INCORRECT_PASSCODE;
    //   case '':
    //     return ContentEnum.MFA_EXPIRED_PASSCODE;
    //   default:
    //     return error.apiErrorMsg;
    // }
  }

  public static unresolvableResponse(error: AbxErrorResponse): boolean {
    return error.status === ServerCode.UNRESOLVABLE;
  }

  public static buildBlankErrorWithMsg(msg: string): AbxErrorResponse {
    return {
      status: undefined,
      message: undefined,
      url: undefined,
      apiErrorCode: undefined,
      apiErrorMsg: msg,
    } as unknown as AbxErrorResponse;
  }
}
