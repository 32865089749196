import { Component, ChangeDetectionStrategy, Signal, inject, computed, effect, model } from '@angular/core';
import { ClientHistory, BodyFatTestDetails } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum } from '@bodyanalytics/ui-core';
import { EChartsOption, SeriesOption } from 'echarts';
import { CustomerDashboardService } from '../../services/customer-dashboard.service';
import { DecimalPipe } from '@angular/common';
import { NgxEchartsDirective } from 'ngx-echarts';
import { CustomerDashboardStore } from '../../store/customer-dashboard.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

const LB = 0.45359237;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-customer-dashboard.container',
  standalone: true,
  styleUrl: './customer-dashboard.component.scss',
  templateUrl: './customer-dashboard.component.html',
  imports: [
    DecimalPipe,
    NgxEchartsDirective,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
  ],
})
export class CustomerDashboardComponent extends BodyanalyticsBaseComponent {
  protected reports = toSignal(this.service.findAllCompletedTestReport(), { initialValue: [] });
  protected userName = computed(() => {
    console.log(this.reports()[0]?.report.id);
    return this.reports()[0]?.customer.firstName;
  });
  protected lbsKg = model<boolean>();
  protected testDetails = computed<BodyFatTestDetails | undefined>(() => this.reports()[0]?.report.testDetails);
  protected weightsChart = computed<EChartsOption>(this.#weightTimeSeriesData.bind(this));
  protected bodyPercentagesChart = computed<EChartsOption>(this.#massFractionTimeSeriesData.bind(this));
  protected bodyCompositionChart = computed<EChartsOption>(this.#bodyCompositionData.bind(this));
  protected contentEnum = ContentEnum;
  #store = inject(CustomerDashboardStore);
  protected clientHistory: Signal<ClientHistory[]> = this.#store.clientHistory;

  constructor(private service: CustomerDashboardService) {
    super();

    effect(() => this.#store.loadClientHistory(), { allowSignalWrites: true });
  }

  protected downloadLatestResult() {
    this.#store.downloadLatestResult();
  }

  #weightTimeSeriesData(clientHistory = this.clientHistory(), lbsKg = this.lbsKg()): EChartsOption {
    const sortedClientHistory = [...clientHistory].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    const dates: string[] = sortedClientHistory.map(item => {
      const d = new Date(item.date);
      return `${d.getMonth() + 1}/${d.getDate() + 1}/${d.getFullYear()}`;
    });
    const prepareDatesAndData = (colName: keyof ClientHistory) =>
      sortedClientHistory.map(item => {
        const col = item[colName] as number;


        return (lbsKg ? Number(col) : Number(col * LB))?.toFixed(2);
      });

    const legendData: { name: string; key: keyof ClientHistory }[] = [
      { name: `Water ${lbsKg ? 'Kg.' : 'Lb.'}`, key: 'waterLbs' },
      { name: `Lean ${lbsKg ? 'Kg.' : 'Lb.'}`, key: 'leanLbs' },
      { name: `Fat ${lbsKg ? 'Kg.' : 'Lb.'}`, key: 'fatLbs' },
      { name: `Dry Weight ${lbsKg ? 'Kg.' : 'Lb.'}`, key: 'dryWeight' },
    ];

    const series: SeriesOption[] = legendData.map(({ name, key }) => ({
      name,
      data: prepareDatesAndData(key),
      type: 'line',
    }));

    return {
      legend: { data: legendData, left: '0%' },
      tooltip: { order: 'seriesDesc', trigger: 'axis' },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: {
        boundaryGap: false,
        data: [...dates],
        name: 'Date, mm/dd/yyy',
        nameGap: 30,
        nameLocation: 'middle',
        type: 'category',
      },
      yAxis: {
        nameRotate: 90,
        name: `Weight. ${lbsKg ? 'Kg.' : 'Lb.'}`,
        nameGap: 30,
        nameLocation: 'middle',
        type: 'value',
      },
      series,
    };
  }

  #massFractionTimeSeriesData(clientHistory = this.clientHistory()): EChartsOption {
    // Sort clientHistory by date in ascending order
    const sortedClientHistory = [...clientHistory].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    const xAches: string[] = sortedClientHistory.map(item => {
      const d = new Date(item.date);
      return `${d.getMonth() + 1}/${d.getDate() + 1}/${d.getFullYear()}`;
    });

    const series: SeriesOption[] = (
      [
        { name: 'Fat %', colName: 'fatPercent' },
        { name: 'Lean %', colName: 'leanMassPercent' },
      ] satisfies { name: string; colName: keyof ClientHistory }[]
    ).map(({ name, colName }) => ({
      name,
      data: sortedClientHistory.map(item => item[colName]),
      type: 'line',
    }));

    return {
      legend: { data: ['Water %', 'Lean %', 'Fat %'], left: '0%' },
      tooltip: { order: 'seriesDesc', trigger: 'axis' },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: {
        boundaryGap: false,
        data: [...xAches],
        name: 'Date, mm/dd/yyyy',
        nameGap: 30,
        nameLocation: 'middle',
        type: 'category',
      },
      yAxis: {
        name: 'Mass Fraction, %',
        nameLocation: 'middle',
        nameGap: 30,
        type: 'value',
      },
      series,
    };
  }

  #bodyCompositionData(testDetails = this.clientHistory()[0]): EChartsOption {
    return {
      legend: { left: '0%' },
      dataset: [
        {
          source: [
            { value: testDetails?.leanMassPercent, name: 'Lean Lb.' },
            { value: testDetails?.fatPercent, name: 'Fat Lb.' },
          ],
        },
      ],
      series: [
        {
          type: 'pie',
          label: { position: 'inside', formatter: '{d} %', color: 'black', fontSize: 14 },
          percentPrecision: 1,
        },
      ],
    };
  }
}
