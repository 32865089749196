<header class="border-bottom bg-light d-grid">
  <div class="px-3 py-2 text-bg-dark border-bottom">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a
          class="navbar-brand d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
          routerLink="/home">
          <img src="./assets/images/ABX-logo-white.png" alt="logo image" class="logo" />
        </a>
        <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
          <li *ngIf="isCustomerPortal() || isAdminPortal()">
            <bodyanalytics-calendar-button></bodyanalytics-calendar-button>
          </li>
          <!-- <li>
            <bodyanalytics-profile-button *ngIf="auth.isAuthenticated$ | async"></bodyanalytics-profile-button>
          </li> -->
          <li *ngIf="isCustomerPortal()">
            <bodyanalytics-member-button *ngIf="auth.isAuthenticated$ | async"></bodyanalytics-member-button>
          </li>
          <li *ngIf="isAdminPortal()">
            <bodyanalytics-admin-member-button
              *ngIf="auth.isAuthenticated$ | async"></bodyanalytics-admin-member-button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <bodyanalytics-nav class="container d-flex py-2 align-items-center" />
</header>
