import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'bodyanalytics-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() title: string = '';
  @Input() body: TemplateRef<any> | null = null;
  @Input() footer: TemplateRef<any> | null = null;
  @Input() size: 'sm' | 'lg' | 'xl' | '' = '';
  isOpen: boolean = false;

  get sizeClass() {
    return {
      'modal-sm': this.size === 'sm',
      'modal-lg': this.size === 'lg',
      'modal-xl': this.size === 'xl',
    };
  }

  constructor(private modalService: ModalService, private el: ElementRef) {}

  ngOnInit(): void {
    if (!this.id) {
      console.error('Modal must have an ID');
      return;
    }

    this.modalService.registerModal(this);

    document.body.appendChild(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    document.body.removeChild(this.el.nativeElement);
  }

  open(): void {
    this.isOpen = true;
    document.body.classList.add('modal-open');
  }

  close(): void {
    this.isOpen = false;
    document.body.classList.remove('modal-open');
  }
}
