<!-- my-dialog.component.html -->
<h2 mat-dialog-title>{{ dialogEnum.CANCEL_DIALOG_HEADER }}</h2>
<mat-dialog-content>
  <!-- Your dialog content goes here -->
  <p>
    {{ dialogEnum.CANCEL_DIALOG_CONTENT_1 }}
    <br />
    {{ dialogEnum.CANCEL_DIALOG_CONTENT_2 }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close class="btn">Cancel</button>
  <button [mat-dialog-close]="true" class="btn btn-primary">Yes, Cancel</button>
</mat-dialog-actions>
