import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bodyanalytics-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();

  constructor(private router: Router, private route: ActivatedRoute) {
    //
  }

  public loginForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  public login(): void {
    this.submitFormEvent.emit({
      emailAddress: this.loginForm.value.emailAddress as string,
      password: this.loginForm.value.password as string,
    });
  }
}
