import { SignalStoreFeature, signalStoreFeature, withMethods, patchState, withHooks } from '@ngrx/signals';
import { SignalStoreFeatureResult, SignalStoreSlices, EmptyFeatureResult } from '@ngrx/signals/src/signal-store-models';
import { effect } from '@angular/core';

export const withLocalStorage = <
  Input extends SignalStoreFeatureResult,
  StateProp extends keyof SignalStoreSlices<Input['state']>,
>(
  namespace: string,
  stateProp: StateProp,
  storageKey = stateProp.toString(),
): SignalStoreFeature<Input, EmptyFeatureResult> =>
  signalStoreFeature(
    withMethods(s => {
      const key = `${namespace}_${storageKey}`;

      return {
        saveToLocalStorage: <T>(state: T) => localStorage.setItem(key, JSON.stringify(state)),
        loadFromLocalStorage: () => {
          try {
            const v = localStorage.getItem(key);

            v && patchState(s, { [stateProp]: JSON.parse(v) });
          } catch {
            console.error(`Couldn't load '${key}' from local storage`);
          }
        },
      };
    }),
    withHooks({
      onInit: s => {
        s.loadFromLocalStorage();
        effect(() => s.saveToLocalStorage((s as any)[stateProp]()));
      },
    }),
  );
