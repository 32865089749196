import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogDataEnum } from '../../../enums/dialog.enum';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'bodyanalytics-manage-club-delete-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './manage-club-delete-dialog.component.html',
  styleUrl: './manage-club-delete-dialog.component.scss',
})
export class ManageClubDeleteDialogComponent {
  protected dialogEnum = DialogDataEnum;
}
