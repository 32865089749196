export enum RouterUrlsEnum {
  AUTH = '/auth',
  AUTH_DASHBOARD = '/auth/dashboard',
  AUTH_ADMIN_DASHBOARD = '/auth/admin-dashboard',
  AUTH_CUSTOMER_DASHBOARD = '/auth/dashboard',
  AUTH_ADMIN_MANAGE_BOOKINGS = '/auth/manage-bookings',
  AUTH_ADMIN_MANAGE_BOOKINGS_DASHBOARD = '/auth/manage-bookings/dashboard',
  AUTH_ADMIN_MANAGE_BOOKINGS_DETAILS = '/auth/manage-bookings/detail',
  AUTH_ADMIN_MANAGE_BOOKINGS_CREATE_BOOKING = '/auth/manage-bookings/create-booking',

  AUTH_ADMIN_MANAGE_BOOKINGS_REVIEW_BOOKING = '/auth/manage-bookings/review-booking',
  AUTH_ADMIN_MANAGE_BOOKINGS_COMPLETE_BOOKING = '/auth/manage-bookings/complete-booking',

  AUTH_ADMIN_MANAGE_COMPANY = '/auth/admin-setup',

  AUTH_ADMIN_MANAGE_CUSTOMERS = '/auth/manage-customers',
  AUTH_ADMIN_MANAGE_CUSTOMERS_DETAILS = '/auth/manage-customers/detail',
  AUTH_ADMIN_MANAGE_CUSTOMERS_CREATE_CUSTOMER = '/auth/manage-customers/create-customer',

  AUTH_ADMIN_MANAGE_CLUBS = '/auth/manage-clubs',
  AUTH_ADMIN_MANAGE_CLUBS_DETAILS = '/auth/manage-clubs/detail',
  AUTH_ADMIN_MANAGE_CLUBS_CREATE_CLUB = '/auth/manage-clubs/create-club',
  AUTH_ADMIN_MANAGE_CLUBS_DASHBOARD = '/auth/manage-clubs/dashboard',

  AUTH_ADMIN_MANAGE_DISCOUNTS = '/auth/manage-discounts',
  AUTH_ADMIN_MANAGE_DISCOUNTS_DETAILS = '/auth/manage-discounts/detail',
  AUTH_ADMIN_MANAGE_DISCOUNTS_CREATE_DISCOUNT = '/auth/manage-discounts/create-discount',

  AUTH_ADMIN_MANAGE_EMAILS = '/auth/manage-emails',
  AUTH_ADMIN_MANAGE_EMAILS_CREATE_EMAIL = '/auth/manage-emails/create-email',
  AUTH_ADMIN_MANAGE_EMAILS_REVIEW_EMAIL = '/auth/manage-emails/review-email',
  AUTH_ADMIN_MANAGE_EMAILS_DETAILS = '/auth/manage-emails/detail',

  AUTH_ADMIN_MANAGE_EVENTS = '/auth/manage-events',
  AUTH_ADMIN_MANAGE_EVENTS_DASHBOARD = '/auth/manage-events/dashboard',
  AUTH_ADMIN_MANAGE_EVENTS_CREATE_EVENT = '/auth/manage-events/create-event',
  AUTH_ADMIN_MANAGE_EVENTS_REVIEW_EVENT = '/auth/manage-events/review-event',
  AUTH_ADMIN_MANAGE_EVENTS_COMPLETE_EVENT = '/auth/manage-events/create-complete',
  AUTH_ADMIN_MANAGE_EVENTS_DETAILS = '/auth/manage-events/detail',

  AUTH_ADMIN_MANAGE_MEALPLANS = '/auth/manage-mealplans',
  AUTH_ADMIN_MANAGE_MEALPLANS_DETAILS = '/auth/manage-mealplans/detail',

  AUTH_ADMIN_MANAGE_OPERATORS = '/auth/manage-operators',
  AUTH_ADMIN_MANAGE_OPERATORS_DETAILS = '/auth/manage-operators/detail',
  AUTH_ADMIN_MANAGE_OPERATORS_CREATE_OPERATOR = '/auth/manage-operators/create-operator',
  AUTH_ADMIN_MANAGE_OPERATORS_DASHBOARD = '/auth/manage-operators/dashboard',

  AUTH_ADMIN_MANAGE_ORDERS = '/auth/manage-orders',
  AUTH_ADMIN_MANAGE_ORDERS_DETAILS = '/auth/manage-orders/detail',

  AUTH_ADMIN_MANAGE_PRODUCTS = '/auth/manage-products',
  AUTH_ADMIN_MANAGE_PRODUCTS_CREATE_PRODUCT = '/auth/manage-products/create-product',
  AUTH_ADMIN_MANAGE_PRODUCTS_CREATE_SERVICE = '/auth/manage-products/create-service',
  AUTH_ADMIN_MANAGE_PRODUCTS_DETAILS = '/auth/manage-products/detail',

  AUTH_ADMIN_MANAGE_PAYMENTS = '/auth/manage-payments',
  AUTH_ADMIN_MANAGE_PAYMENTS_DETAILS = '/auth/manage-payments/detail',

  AUTH_ADMIN_MANAGE_REGIONS = '/auth/manage-regions',
  AUTH_ADMIN_MANAGE_REGIONS_CREATE_REGION = '/auth/manage-regions/create-region',
  AUTH_ADMIN_MANAGE_REGIONS_DETAILS = '/auth/manage-regions/detail',
  AUTH_ADMIN_MANAGE_REGIONS_DASHBOARD = '/auth/manage-regions/dashboard',

  AUTH_ADMIN_MANAGE_SIGNUPS = '/auth/manage-signups',
  AUTH_ADMIN_MANAGE_SIGNUPS_DETAILS = '/auth/manage-signups/detail',
  AUTH_ADMIN_MANAGE_SIGNUPS_CREATE_SIGNUP = '/auth/manage-signups/create-signup',
  AUTH_ADMIN_MANAGE_SIGNUPS_LIST = '/auth/manage-signups/signup-list',

  AUTH_ADMIN_MANAGE_SMS = '/auth/manage-sms',
  AUTH_ADMIN_MANAGE_SMS_DETAILS = '/auth/manage-sms/detail',
  AUTH_ADMIN_MANAGE_SMS_CREATE_SMS = '/auth/manage-sms/create-sms',

  AUTH_ADMIN_MANAGE_SETTINGS = '/auth/manage-settings',
  AUTH_ADMIN_MANAGE_SETTINGS_CREATE_SETTING = '/auth/manage-settings/create-setting',
  AUTH_ADMIN_MANAGE_SETTINGS_DETAILS = '/auth/manage-settings/detail',

  AUTH_ADMIN_MANAGE_TRUCKS = '/auth/manage-trucks',
  AUTH_ADMIN_MANAGE_TRUCKS_CREATE_TRUCK = '/auth/manage-trucks/create-truck',
  AUTH_ADMIN_MANAGE_TRUCKS_DASHBOARD = '/auth/manage-trucks/dashboard',

  AUTH_ADMIN_MANAGE_BFTESTS = '/auth/manage-bodymeasurements',
  AUTH_ADMIN_MANAGE_BFTESTS_DETAIL = '/auth/manage-bodymeasurements/detail',
  AUTH_ADMIN_MANAGE_BFTESTS_REPORT_REVIEW = '/auth/manage-bodymeasurements/report-test',
  AUTH_ADMIN_MANAGE_BFTESTS_START_TEST = '/auth/manage-bodymeasurements/start-test',
  AUTH_ADMIN_MANAGE_BFTESTS_COMPLETE_TEST = '/auth/manage-bodymeasurements/complete-test',

  AUTH_ADMIN_MANAGE_BFTESTS_CHECK_IN = '/auth/manage-bodymeasurements/check-in',
  AUTH_ADMIN_MANAGE_BFTESTS_SETUP_TEST = '/auth/manage-bodymeasurements/setup-test',
  AUTH_ADMIN_MANAGE_USERS = '/auth/manage-users',
  AUTH_ADMIN_MANAGE_USERS_DETAIL = '/auth/manage-users/detail',
  AUTH_ADMIN_MANAGE_USERS_CREATE_USER = '/auth/manage-users/create-user',

  AUTH_REGISTER_PROFILE = '/register/profile',
  AUTH_REGISTER_ACCOUNT = '/register/account',
  AUTH_PROFILE = 'auth/profile',
  LOGIN = 'login',
  LOGOUT = 'logout',
  ERROR = 'error',
  MAINTENANCE = 'maintenance',
  AUTH_CLUBS = '/auth/clubs',
  CALENDER_CHECKOUT = 'calendar/checkout/',

  CALENDER_ORDER_CONFIRMATION = 'calendar/order-confirmation',
  CALENDER_SIGNUP = 'calendar/signup',
  CALENDER_SIGNUP_RESCHEDULE_VERIFY = 'calendar/rechedule-verify',
  CALENDER_SIGNUP_RESCHEDULE_CONFIRM = 'calendar/reschedule-confirmation',
  CALENDAR_SIGNUP_VERIFY = 'calendar/signup-verify/',

  CALENDER_EVENTS_SEARCH = 'calendar/search',

  CALENDER_EVENT_DETAILS = 'calendar/event-details',
  CALENDER_SIGNUP_PAYMENT = '/payment',

}
