import { Route } from '@angular/router';
import { AuthGuard, authGuardFn } from '@auth0/auth0-angular';
import { CustomerDashboardComponent } from './containers/customer-dashboard/customer-dashboard.component';
import { CustomerDashboardStore } from './store/customer-dashboard.store';

export const dashboardUiRoute: Route = {
  path: 'auth/dashboard',
  loadChildren: () => [
    {
      path: '',
      component: CustomerDashboardComponent,
      canActivate: [AuthGuard],
      // resolve: {
      //   hero: DashboardResolver,
      // },
    },

    // {
    //   path: 'auth/manage-contacts',
    //   component: ManageContactsComponent,
    //   children: [
    //     { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    //     {
    //       path: 'dashboard',
    //       component: ManageContactsDashboardComponent,
    //       pathMatch: 'full',
    //     },
    //     {
    //       path: 'detail/:id',
    //       component: ManageContactsDetailComponent,
    //     },
    //   ],
    // }
  ],
  canMatch: [authGuardFn],
  providers: [CustomerDashboardStore],
};
