import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProfileDetailsI, RegistrationI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum } from '@bodyanalytics/ui-core';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(@Inject(ENV) private appConfig: any, private readonly http: HttpClient) {}

  createUser(userData: RegistrationI) {
    const url = `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_REGISTER}`;
    return this.http.post(`${url}`, userData);
  }
  createProfile(profile: ProfileDetailsI) {
    const url = `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE}`;
    return this.http.post(url, profile);
  }
}
