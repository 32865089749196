import { Component } from '@angular/core';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';
import { RegisterService } from '../../services/register.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bodyanalytics-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent extends BodyanalyticsBaseComponent {
  public readonly pageHeader = 'sms';
  constructor(private router: Router, private registerService: RegisterService) {
    super();
  }
}
