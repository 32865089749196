import { signalStore, withState, withMethods, patchState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, exhaustMap, tap } from 'rxjs';
import { inject } from '@angular/core';
import { CustomerDashboardService } from '../services/customer-dashboard.service';
import { tapResponse } from '@ngrx/operators';
import { ClientHistory } from '@bodyanalytics/data-models-ui';

interface State {
  clientHistory: ClientHistory[];
  loaded: boolean;
}

const state: State = {
  clientHistory: [],
  loaded: false,
};

export const CustomerDashboardStore = signalStore(
  withState(state),
  withMethods((s, service = inject(CustomerDashboardService)) => ({
    loadClientHistory: rxMethod<void>(
      switchMap(() => {
        patchState(s, { loaded: false });

        return service.loadClientHistory().pipe(
          tapResponse({
            next: clientHistory => patchState(s, { clientHistory, loaded: true }),
            error: error => console.error(error),
          }),
        );
      }),
    ),
    downloadLatestResult: rxMethod<void>(
      exhaustMap(() =>
        service.downloadLatestResult().pipe(
          tapResponse({
            next: buffer => {
              const url = URL.createObjectURL(new Blob([buffer as ArrayBuffer]));
              const link = document.createElement('a');

              link.href = url;
              link.download = `latest-result.pdf`;
              link.click();
              URL.revokeObjectURL(url);
            },
            error: error => console.error(error),
          }),
        ),
      ),
    ),
  })),
);
