export interface OrderI {
  id: string;
  firstName: string;
}
// address: '800 Stan Circle';
// bodyFatMeasureTestProductId: null;
// city: 'New Judahstead';
// country: 'USA';
// createdAt: '2023-11-09T00:15:09.368Z';
// customerId: null;
// emailAddress: 'Funk';
// employee_id: null;
// firstName: 'Claudia';
// freight: null;
// id: 'add4b065-c42d-4a8e-8db4-8c16572e4db9';
// lastName: 'Funk';
// linksId: null;
// mealPlanId: null;
// operatorId: null;
// orderDate: '2023-11-09T00:15:09.368Z';
// orderNumber: 'qCksW';
// paymentId: null;
// required_date: '2023-11-09T00:00:00.000Z';
// ship_via: null;
// shipped_date: '2024-10-04T00:00:00.000Z';
// testingEventId: null;
// trainerId: null;
// updatedAt: '2023-11-09T00:15:09.368Z';
// zipCode: '31533-1200';
export interface OrderTotalResponseI{
  total: number;
}
