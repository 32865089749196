<!-- my-dialog.component.html -->
<h2 mat-dialog-title>{{ dialogEnum.RESCHEDULE_DIALOG_HEADER }}</h2>
<mat-dialog-content>
  <!-- Your dialog content goes here -->
  <p>
    {{ dialogEnum.RESCHEDULE_DIALOG_CONTENT }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close class="btn">Back to Booking</button>
  <button [mat-dialog-close]="true" class="btn btn-primary">Choose New Event</button>
</mat-dialog-actions>
