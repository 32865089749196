import { Injectable } from '@angular/core';

export interface DialogData {
  id: string;
  open: () => void;
  close: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: { [id: string]: DialogData } = {};

  constructor() {}

  registerModal(modal: DialogData) {
    this.modals[modal.id] = modal;
  }

  open(id: string) {
    const modal = this.modals[id];
    if (modal) {
      modal.open();
    } else {
      console.warn(`Modal with id ${id} not found`);
    }
  }

  close(id: string) {
    const modal = this.modals[id];
    if (modal) {
      modal.close();
    } else {
      console.warn(`Modal with id ${id} not found`);
    }
  }

  openTimeoutModal(id: string, timeout: number) {
    setTimeout(() => {
      this.open(id);
    }, timeout);
  }
}
