import { Component, Input } from '@angular/core';
import { ChartDataI, ColumnI, RowI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-generic-dashboard',
  templateUrl: './generic-dashboard.component.html',
  styleUrls: ['./generic-dashboard.component.scss'],
})
export class GenericDashboardComponent {
  @Input()
  customerName!: string;
  @Input()
  chartOptions: ChartDataI[] | undefined; // Assuming multiple chart options
  @Input()
  rows: RowI[] = [];
  columns = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'twitterHandle', label: 'Twitter Handle' },
  ] as ColumnI[];

  data: RowI[] = [
    { data: { firstName: 'Alice', lastName: 'Smith', twitterHandle: '@alice_smith' } },
    // ... more data
  ];
}
