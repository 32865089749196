import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ENV } from '@bodyanalytics/app-config';

@Component({
  selector: 'bodyanalytics-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) private appConfig: any,
  ) {}

  public isCustomerPortal(): boolean {
    if (this.appConfig.portalName === 'customer') {
      return true;
    }

    return false;
  }
  public isCalendarPortal(): boolean {
    if (this.appConfig.portalName === 'calendar') {
      return true;
    }

    return false;
  }
  public isAdminPortal(): boolean {
    if (this.appConfig.portalName === 'admin') {
      return true;
    }
    return false;
  }
}
