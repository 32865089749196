import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss'],
})
export class ProfileButtonComponent {
  constructor(private router: Router) {}
  profile() {
    //create a register route
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_PROFILE}`);
    //route to signup page
    // let users find themselves in our db
    // ask user questions to link their account
    // if user provides the right information, help them create a profile
    //ask survey questions
    // do not show dashboard if we don't have any test data
  }
}
