import { BookingI } from './booking';

export interface CustomerI {
  // contact_name: string;
  id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  age: number;
  ethnicity: string;
  emailAddress: string;
  phoneNumber: string;
  sex: string;
  gender: string;
  height: number;
  updatedAt: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  club?: string;
  isEmployee: boolean;
  isAfricanAmerican?: boolean;
  isSiri3?: boolean;
  useSiri3?: boolean;
  updateDate?: string;
  createdAt?: string;
  booking: BookingI[];
}
