import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnI, RowI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent {
  @Input()
  columns: ColumnI[] = [];
  @Input()
  rows: RowI[] = [];

  @Output() tableEvent = new EventEmitter<any>();

  ngOnInit() {
    // Initialize table data if needed
  }
  onColumnClick(column: ColumnI) {
    // Handle column click events (e.g., sorting)
  }

  onEditRow(row: RowI) {
    // Emit an event to notify the parent component about editing a row
    this.tableEvent.emit({ action: 'edit', rowData: row.data });
  }

  onDeleteRow(index: number) {
    // Remove the row from the rows array
    this.rows.splice(index, 1);
    // Emit an event to notify the parent component about the deletion
    this.tableEvent.emit({ action: 'delete', rowIndex: index });
  }
  onCheckboxChange(event: any) {
    // Emit an event with the selected row's ID and checked state
    this.tableEvent.emit({ action: 'checkboxChange', rowId: event.target.value, checked: event.target.checked });
  }
}
