// generic-table.component.ts
export interface ColumnI {
  key: string;
  label: string;
  tooltip?: string;
  sortable?: boolean;
  filterable?: boolean;
}

export interface RowI {
  data: any;
}
