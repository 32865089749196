import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthService, User } from '@auth0/auth0-angular';
import { LoginI } from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { ENV } from '@bodyanalytics/app-config';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private userSubject$ = new BehaviorSubject<User>({} as User);
  user$ = this.userSubject$.asObservable();
  private returnUrl: string = '/';

  constructor(
    @Inject(ENV) private appConfig: any,
    private httpClient: HttpClient,
    private router: Router,
    private authService: AuthService,
  ) {
    const user = localStorage.getItem('user');
    if (user) {
      this.userSubject$.next(JSON.parse(user));
    }
  }
  setReturnUrl(url: string) {
    this.returnUrl = url;
  }

  getReturnUrl(): string {
    return this.returnUrl;
  }
  login(authenticate: LoginI): Observable<User> {
    return this.httpClient
      .post<User>(`${this.appConfig.api}/}${ApiLocalRoutesEnum.API_SUFFIX_LOGIN}`, authenticate)
      .pipe(
        tap((user: User) => {
          this.userSubject$.next(user);
          localStorage.setItem('user', JSON.stringify(user));
          this.router.navigate([`${RouterUrlsEnum.AUTH_DASHBOARD}`]);
        }),
      );
  }

  logout(authenticate: any): Observable<User> {
    return this.httpClient
      .post<User>(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_LOGOUT}`, authenticate)
      .pipe(
        tap((user: User) => {
          this.userSubject$.next({} as User);
          this.router.navigate([`${RouterUrlsEnum.LOGOUT}`]);
          // localStorage.setItem('user', JSON.stringify(user));
        }),
      );
  }
}
