<!-- my-dialog.component.html -->
<h2 mat-dialog-title>{{ dialogEnum.DELETE_CLUB_DIALOG_HEADER }}</h2>
<mat-dialog-content>
  <!-- Your dialog content goes here -->
  <p>
    {{ dialogEnum.DELETE_CLUB_DIALOG_CONTENT }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close class="btn">Cancel</button>
  <button [mat-dialog-close]="true" class="btn btn-primary">Yes, Delete</button>
</mat-dialog-actions>
