import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '../../services/register.service';
import { RegistrationI } from '@bodyanalytics/data-models-ui';

import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'bodyanalytics-register-init',
  templateUrl: './register-init.component.html',
  styleUrls: ['./register-init.component.scss'],
})
export class RegisterInitComponent extends BodyanalyticsBaseComponent {
  public signupDetailsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    // role: new FormControl('', [Validators.required]),
    // confirmEmailAddress: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    // confirmPassword: new FormControl('', [Validators.required]),
    // firstName: new FormControl('', [Validators.required]),
    // lastName: new FormControl('', [Validators.required]),
    // phoneNumber: new FormControl('', [Validators.required]),
  });

  constructor(
    private router: Router,
    private registerService: RegisterService,
    public auth: AuthService,
  ) {
    super();
  }

  update(registration: RegistrationI) {
    this.subscriptions.barrel = this.registerService.createUser(registration).subscribe(response => {
      console.log('response: ', response);
      if (response === 204) {
        // show success message and prompt to login
        this.auth.loginWithRedirect();
      }
    });
    // this.router.navigate([`${RouterUrlsEnum.AUTH_REGISTER_PROFILE}`]);
    // this.subscriptions.barrel = this.registerService.createProfile(login).subscribe();
  }
}
