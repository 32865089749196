export interface CustomerTestingI {
  id: number;
  testDate: string;
  WaterLbs: number;
  Weight: number;
  Height: number;
  RLV: number;
  Temperature: number;
  WetKg: number;
  FatLbs: number;
  FatPercent: number;
  LeanLbs: number;
  LeanMassPercent: number;
  TruckNumber: number;
  RMR: number;
  UID: string;
  clubId: string;
  operatorId: string;
  createdAt: string;
  updatedAt: string;
  profileId: string;
  noteId: string;
  Training: false;
  UpdateDate: string;
}

export interface SetupTestingI {
  operators: string;
  trucks: string;
  events: string;
  waterTemperature: number;
  customerDetails: any;
  testData: any;
  date: any;
}
