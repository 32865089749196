export interface OperatorI {
  id: string;
  emailAddress?: string;
  operatorUserName?: string;
  firstName?: string;
  lastName?: string;
  operatorPriority?: number;
  uid?: string;
  operatorUserId?: string;
  operatorVerifiedPinId?: string;
  operatorProfileId?: string;
  createdAt?: string;
  updatedAt?: string;
}
