import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BodyanalyticsBaseComponent, SessionStorageService } from 'libs/front/shared/ui-core/src';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap } from 'rxjs';
import { ENV } from '@bodyanalytics/app-config';

@Component({
  selector: 'bodyanalytics-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent extends BodyanalyticsBaseComponent implements OnInit {
  returnUrl: string = '';
  constructor(
    private sessionSessionService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private loginService: LoginService,
    private router: Router,
    @Inject(ENV) private appConfig: any,
  ) {
    super();
  }
  ngOnInit(): void {
    const bookingData = this.sessionSessionService.getBookingData();
    const workflowStep = bookingData?.workflowStep ?? 'search';
    const eventId = bookingData?.selectedEvent?.id;

    console.log('workflowStep: ', workflowStep);
    switch (workflowStep) {
      case 'checkout':
        this.returnUrl = `${RouterUrlsEnum.CALENDER_CHECKOUT}${eventId}`;
        break;
      case 'signup-verify':
        this.returnUrl = `${RouterUrlsEnum.CALENDAR_SIGNUP_VERIFY}${eventId}`;
        break;
      case 'search':
        this.returnUrl = `${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`;
        break;
      default:
        this.returnUrl = `${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`; // Define a default URL if needed
        break;
    }

    console.log('returnUrl: ', this.returnUrl);

    this.auth.isAuthenticated$
      .pipe(
        tap(loggedIn => {
          console.log('is user logged in? ', loggedIn);
          if (loggedIn) {
            console.log('send them here:', this.returnUrl);
            this.router.navigateByUrl(this.returnUrl);
          } else if (this.returnUrl.indexOf('signup-verify')) {
            this.router.navigate([`${RouterUrlsEnum.LOGIN}`]);
          } else {
            this.router.navigate([`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}`]);
            this.sessionSessionService.clear();
          }
        }),
      )
      .subscribe();
  }

  // public login(login: LoginI) {
  //   this.subscriptions.barrel = this.loginService
  //     .login(login)

  //     .subscribe();
  // }

  signup() {
    this.loginService.setReturnUrl(this.returnUrl);
    this.router.navigate([`${RouterUrlsEnum.AUTH_REGISTER_ACCOUNT}`]);
  }

  public isCalendarPortal(): boolean {
    if (this.appConfig.portalName === 'calendar') {
      return true;
    }

    return false;
  }
  public isAdminPortal(): boolean {
    if (this.appConfig.portalName === 'admin') {
      return true;
    }
    return false;
  }
}
