export interface EventDetailsI {
  id: number;
  name: string;
  address: AddressI;
  eventDate: string;
  eventTimeStart: string;
  eventTimeEnd: string;
  eventUrl: string;
  challengeCodes: ChallengeCodesI[];
}

export interface ChallengeCodesI {
  code: string;
}
export interface AddressI {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  mapCoordinates: string;
}
