export enum DialogDataEnum {
  CANCEL_DIALOG_HEADER = 'Confirm Cancellation',
  //Are you sure you want to cancel your booking?
  CANCEL_DIALOG_CONTENT_1 = `By confirming, you will cancel your booking for this event. Don't worry, though-a
  credit will be applied to your account for future use.`,
  CANCEL_DIALOG_CONTENT_2 = `Remember, you have the option to reschedule at any time if
  your plans change.`,

  DELETE_CLUB_DIALOG_HEADER = `Confirm Deletion`,
  DELETE_CLUB_DIALOG_CONTENT = `Are you sure you want to delete club?`,


  DELETE_EVENT_DIALOG_HEADER= `Confirm Deletion`,
  DELETE_EVENT_DIALOG_CONTENT = `Are you sure you want to delete event?`,


  RESCHEDULE_DIALOG_HEADER = `Ready to Reschedule?`,
  RESCHEDULE_DIALOG_CONTENT = `Looks like you need a change of plans! Rescheduling is easy: we'll guide you to our events page where you can choose a new time or a different event that suits you better. Just a few clicks and you're all set for your new appointment. Your previous booking will be cancelled automatically upon the selection of a new slot.`,
  RESCHEDULE_DIALOG_CONTENT2 = `Not ready to decide? Click here to return to your current booking details. Take your time, we're here when you need us.`,
  RESCHEDULE_DIALOG_CONTENT3 = `Click here to be redirected to our events page to select a new time or event. Your journey to a fresh experience starts here!`,
}
