<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  [id]="id"
  [ngClass]="{ show: isOpen }"
  [style.display]="isOpen ? 'block' : 'none'">
  <div class="modal-dialog" [ngClass]="sizeClass" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="body" [ngTemplateOutlet]="body"></ng-container>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="footer" [ngTemplateOutlet]="footer"></ng-container>
      </div>
    </div>
  </div>
</div>
