import Stripe from 'stripe';
import { ServicesI } from './services';

export interface ProductDataI {
  name: string;
  services: ServiceDataI[];
}

export interface ServiceDataI {
  name: string;
  price: number;
}

export interface ProductListI {
  // has_more: string;
  // object: string;
  // url: string;
  // data: ProductI[]
  object: string;
  data: Product[];
  has_more: boolean;
  url: string;
}

export interface ProductsResponseI {
  object: string;
  data: ProductI[];
  has_more: boolean;
  url: string;
}

export interface ProductServiceI {
  id: string;
  serviceName: string;
  price: number;
  duration: number;
  primary: boolean;
}
export interface EventProductServiceI{
  id: string;
  stripeProductId: string;
}
export interface ProductI {
  id: string;
  name: string;
  images: string;
  prices: Stripe.Price[];
  tax_code: string;
  // price: number;
}
export interface ProductToServiceI {
  products: ProductI;
  service: ServicesI;
}

export interface CartI {
  // price?: number
  name: string;
  product: ProductI;
  quantity: number;
}
// "id": "0a3489c0-d587-41b8-ab2a-9a138566ebb4",
// "productName": "Test Awesome Concrete Soap",
// "description": "The beautiful range of Apple Naturalé that has an exciting mix of natural ingredients. With the Goodness of 100% Natural Ingredients",
// "image": "https://loremflickr.com/640/480?lock=1533713043161088",
// "price": 67,
// "productLinksId": null,
// "productRateId": null,
// "orderId": null,
// "sku": "978-0-17-780002-3",
// "quantity_per_unit": null,
// "unit_price": null,
// "units_in_stock": null,
// "units_on_order": null,
// "reorder_level": null,
// "discontinued": null,
// "categories_id": null,
// "suppliers_id": null,
// "createdAt": "2023-11-11T15:45:15.037Z",
// "updatedAt": "2023-11-11T15:45:15.037Z"

export class ProductClass {
  constructor(
    public id?: number,
    public image?: string,
    public name?: string,
    public productName?: string,
    public category?: string,
    public description?: string,
    public price?: number,
    public tax?: number,
    public serviceFee?: number,
    public quantity?: number,
    public unit?: number,
  ) {}
}

export interface Product {
  id: number;
  name: string;
  price: number;
  priceFormatted: string;
  description: string;
}

export interface ProductPricingI {
  id: number;
  serviceName: string;
  servicePrice: number;
  serviceDiscountedPrice: number;
  serviceDuration: number;
  // phone: string;
  // email: string;
  // "AllowEmailNotification": false,
  // "AllowSMSNotification": false,
  // "AllowAlternateEmailNotification": false,
  // "IsAcceptingNewClients": true,
  // "IsAvailable": true,
  // "SMSProviderID": 0,
  // "HasServices": false,
  // "SmsPhoneNumber": null,
  // "SmsOptInDate": null,
  // "IsSmsStopFromPhoneNumber": false,
  // "Description": "",
  // image: string;
  subTotal: number;
  serviceFee: number;
  total: number;
  orderTotal: number;
}
