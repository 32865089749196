import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RouterUrlsEnum } from '../../enums/router-urls.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationHubService implements OnDestroy {
    private readonly navigationEnum = RouterUrlsEnum;

    private currentUrl: string;
    private previousUrl!: string;

    public get _currentUrl() {
      return this.currentUrl;
    }

    public get _previousUrl() {
      return this.previousUrl;
    }

    private readonly destroy$ = new Subject<boolean>();

    public readonly triggerInterstitialSource = new Subject<boolean>();
    private readonly _interstitialEvent$ = this.triggerInterstitialSource.asObservable();
    get interstitialEvent(): Observable<boolean> {
      return this._interstitialEvent$;
    }

    constructor(private router: Router) {
      this.currentUrl = this.router ? this.router.url : '';

      if (this.router && this.router.events) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            window.scrollTo(0, 0);
          }
        });
      }
    }

    public routeToHome(): void {
      this.router.navigateByUrl(`/${this.navigationEnum.CALENDER_EVENTS_SEARCH}`);

    }

    public routeToLogin(): void {
      this.router.navigateByUrl(`/${this.navigationEnum.LOGIN}`);
    }

    public routeToAuth(): void {
      this.router.navigateByUrl(`/${this.navigationEnum.CALENDER_CHECKOUT}`);
    }

    public routeToMaintenance(): void {
      this.router.navigateByUrl(`/${this.navigationEnum.MAINTENANCE}`);
    }

    public routeToErrorRoute(): void {
      this.router.navigateByUrl(`/${this.navigationEnum.ERROR}`);
    }

    // public routeToAemRoute(url: string): void {
    //   this.router.navigateByUrl(`${url}`);
    // }



    public ngOnDestroy(): void {
      this.destroy$.next(true);
    }
  }
