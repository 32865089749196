import { ClubI, EthnicityI, EventStatusDataI, GenderI, RacesI, SexI, StateI } from '@bodyanalytics/data-models-ui';
import { EVENT_STATUS } from '../../../../data-models-ui/src/lib/testing-events';

export const setupTestEventDateDefault = [
  {
    id: 0,
    clubId: '4bb921ae-ab03-40e7-bc4c-4f20174c143f',
    truckId: 'f590abd6-202d-4391-abc4-f7bf5a829243',
    operatorId: 'clqcvyg9q0003xjnercyvuyba',
    regionId: '71c33edf-0533-4905-bfd8-35626c4c54e3',
    imageUrl: 'https://loremflickr.com/640/480?lock=203742886494208',
    startTime: '9:00',
    endTime: '10:00',
    breakStartTime: '10:10',
    breakEndTime: '10:20',
    signupInterval: 'THIRTY_MIN', //FIFTEEN_MIN
    eventName: 'Fitness Challenge 21',
    eventDescription: 'My challenge for 2023',
    status: 'OK',
    slug: 'EVENT_DAL_011',
    serviceId: '17a5ed78-267a-48f1-bb3a-0c8d41574263',
    productId: '17325a14-1e86-4f26-ad6e-08e4f7a3717f', //'prod_NMGvNyl4cVR3nM' //0333db5d-b5a3-43ee-ace9-01e21c377959
  },
];

export const eventStatusData: EventStatusDataI[] = [
  {
    id: '1',
    status: EVENT_STATUS.OK,
  },
];

export const raceData: RacesI[] = [

  {
    id: '1',
    name: 'White',
    value: 'White',
    code: 'WHT',
  },
  {
    id: '2',
    name: 'Black or African American',
    value: 'Black or African American',
    code: 'BLK',
  },
  {
    id: '3',
    name: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
    code: 'AIAN',
  },
  {
    id: '4',
    name: 'Asian',
    value: 'Asian',
    code: 'ASN',
  },
  {
    id: '5',
    name: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
    code: 'NHPI',
  },
  {
    id: '6',
    name: 'Two or More Races',
    value: 'Two or More Races',
    code: 'TOM',
  },
  {
    id: '7',
    name: 'Rather Not Say',
    value: 'R',
    code: 'R',
  },
];
export const sexData: SexI[] = [
  {
    id: '1',
    name: 'Male',
    value: 'M',
  },
  {
    id: '2',
    name: 'Female',
    value: 'F',
  },
  {
    id: '4',
    name: 'Other',
    value: 'O',
  },
  {
    id: '3',
    name: 'Rather Not Say',
    value: 'R',
  },
];

export const genderData: GenderI[] = [
  {
    id: '1',
    name: 'Male',
    value: 'M',
  },
  {
    id: '2',
    name: 'Female',
    value: 'F',
  },
  {
    id: '4',
    name: 'Other',
    value: 'O',
  },
  {
    id: '3',
    name: 'Rather Not Say',
    value: 'R',
  },
];

export const clubData: ClubI[] = [
  {
    id: 'f5596581-992e-4bdd-b0cd-ceead0bb11ac',
    clubName: 'ClubDickens Group',
    contactName: 'Trainer Kelley',
    address1: '98851 Jayce Heights',
    address2: '',
    city: 'Willmston',
    emailAddress: 'Marge76@gmail.com',
    contactTitle: 'International Program Strategist',
    note: 'Laudantium aegrotatio architecto caritas.',
    state: 'GA',
    zipCode: '02893-1833',
    phoneNumber: '1-992-343-7349',
    uid: undefined,
    lastAmount: 0,
    commission: 0,
    clubLogo: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/62.jpg',
    signupUrl: null,
    isClubACompany: false,
    clubInRegionId: null,
    clubNoteId: null,
    clubTeamsId: null,
    createdAt: '2023-11-11T23:22:01.763Z',
    updatedAt: '2023-11-11T23:22:01.763Z',
  },
];

export const ethnicityData: EthnicityI[] = [
  {
    id: '1',
    name: 'Hispanic or Latino',
    value: 'HISP',
  },
  {
    id: '2',
    name: 'Not Hispanic or Latino',
    value: 'NHISP',
  },
  // {
  //   id: '2',
  //   name: 'White',
  //   value: '',
  // },
];

// export const stateData: StateI[] = [
//   {
//     id: '1',
//     name: 'Texas',
//     value: 'TX',
//   },
//   {
//     id: '2',
//     name: 'Oklahoma',
//     value: 'OK',
//   },
// ];

export const bookingSlots = [
  {
    id: 1,
    name: '8:00 AM',
    slotTime: '2023-07-25T12:00:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 2,
    name: '8:30 AM',
    slotTime: '2023-07-25T12:15:37.000Z',
    isAvailable: false,
    isSelected: false,
  },
  {
    id: 3,
    name: '8:30 AM',
    slotTime: '2023-07-25T12:30:37.000Z',
    isAvailable: false,
    isSelected: false,
  },
  {
    id: 4,
    name: '10:30 AM',
    slotTime: '2023-07-25T12:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 5,
    name: '10:30 AM',
    slotTime: '2023-07-25T13:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 6,
    name: '10:30 AM',
    slotTime: '2023-07-25T13:15:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 7,
    name: '10:30 AM',
    slotTime: '2023-07-25T13:30:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 8,
    name: '10:30 AM',
    slotTime: '2023-07-25T13:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 9,
    name: '10:30 AM',
    slotTime: '2023-07-25T14:00:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 10,
    name: '10:30 AM',
    slotTime: '2023-07-25T14:15:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 11,
    name: '10:30 AM',
    slotTime: '2023-07-25T14:30:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 12,
    name: '10:30 AM',
    slotTime: '2023-07-25T14:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 13,
    name: '10:30 AM',
    slotTime: '2023-07-25T15:00:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 14,
    name: '10:30 AM',
    slotTime: '2023-07-25T15:15:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 15,
    name: '10:30 AM',
    slotTime: '2023-07-25T15:30:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 16,
    name: '10:30 AM',
    slotTime: '2023-07-25T15:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 17,
    name: '10:30 AM',
    slotTime: '2023-07-25T16:00:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 18,
    name: '10:30 AM',
    slotTime: '2023-07-25T16:15:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 19,
    name: '10:30 AM',
    slotTime: '2023-07-25T16:30:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
  {
    id: 20,
    name: '10:30 AM',
    slotTime: '2023-07-25T16:45:37.000Z',
    isAvailable: true,
    isSelected: false,
  },
];
export const mobileTestingService = [
  {
    id: 1,
    name: 'Body Composition Analysis',
    serviceCode: 'BODYFATANALYSIS',
    serviceDescription: '15 minute analysis',
    price: 59,
    priceFormatted: '59.00',
  },
];
export const testProducts = [
  {
    id: 1,
    name: 'Prepaid - 2nd Test',
    price: 0,
    description: 'A large phone with one of the best screens',
  },
  {
    id: 2,
    name: 'One time Single Test',
    price: 0,
    description: 'A great phone with one of the best cameras',
  },
  {
    id: 3,
    name: 'Two tests',
    price: 299,
    description: '',
  },
];
export const profileJson = {
  firstName: 'Penny',
  lastName: 'Jones',
  email: 'penny@mail.com',
  address1: '500 Main Street',
  address2: '',
  zipcode: '75056',
  city: 'Dallas',
  state: 'TX',
  phoneNumber: '800-888-8888',
  dob: '01/12/2000',
  gender: 'Female',
  race: 'White',
  sex: 'Female',
};
export const productPricingData = [
  {
    id: 1,
    serviceName: 'Body Composition Analysis',
    servicePrice: 59,
    serviceDiscountedPrice: 0,
    serviceDuration: 15,
    subTotal: 59.0,
    serviceFee: 2.07,
    total: 61.07,
    orderTotal: 61.07,

    // name: 'Body Composition Analysis',
    // serviceCode: 'BODYFATANALYSIS',
    // serviceDescription: '15 minute analysis',
    // price: 59,
    // priceFormatted: '59.00',
  },
];

export const orderDetails = [
  {
    booking: {
      signupId: '',
      signupCode: '',
      signupEventSlotDate: '1/5/2019 11:10 AM',
    },
    club: {
      name: 'Sanctum Crossfit',
    },
    order: {
      orderCode: '#SK2356',
      orderDate: '1/5/2019 11:10 AM',
      items: [{}],
    },
    customer: {
      name: 'Claudia',
      lastName: 'Ber',
      email: 'mail@gmail.com',
      phoneNumber: '900-990-9999',
    },
  },
];

export const orderConfirmationJson = [
  {
    checkoutId: 'kdfodjfgoiejfe',
    purchaseDate: '2023-07-25T12:00:37.000Z',
    purchaseStatus: 'SUCCESS',

    // orderId: 'ewru32ur8923u',
    customer: {
      firstName: 'Claudia',
      lastName: 'Ber',
    },
    quantity: 1,
    subtotal: 59,
    serviceFee: 3.0,
    total: 62,
    selectService: 'Single Test',
    orderSummaryId: 'SK2356',
    event: {
      gymName: 'My Gym',
      selectedEvent: 'JON_EMIL_2',
      selectedSlot: '8:00AM',
      date: '2023-07-25T12:00:37.000Z',
      organizatorEmail: 'trainer@mail.com',
      organizatorName: 'trainer louie',
    },
  },
  {
    checkoutId: 'kdfodjfgoiejfe',
    purchaseDate: '2023-07-25T12:00:37.000Z',
    purchaseStatus: 'NOT_SUCCESS',

    // orderId: 'ewru32ur8923u',
    customer: {
      firstName: 'Claudia',
      lastName: 'Ber',
    },
    quantity: 1,
    subtotal: 59,
    serviceFee: 3.0,
    total: 62,
    selectService: 'Single Test',
    orderSummaryId: 'SK2356',
    event: {
      gymName: 'My Gym',
      selectedEvent: 'JON_EMIL_2',
      selectedSlot: '8:00AM',
      date: '2023-07-25T12:00:37.000Z',
      organizatorEmail: 'trainer@mail.com',
      organizatorName: 'trainer louie',
    },
    product: {},
  },
];

export const signupInterval = [
  // { id: 1, intervalValue: '5 minutes',  intervalNumValue: '5', intervalId: 'FIVE_MIN' },
  { id: 2, intervalValue: '10 minutes', intervalNumValue: '10', intervalId: 'TEN_MIN' },
  { id: 3, intervalValue: '15 minutes', intervalNumValue: '15', intervalId: 'FIFTEEN_MIN' },
  { id: 4, intervalValue: '20 minutes', intervalNumValue: '20', intervalId: 'TWENTY_MIN' },
  { id: 5, intervalValue: '25 minutes', intervalNumValue: '25', intervalId: 'TWENTY_FIVE_MIN' },
  { id: 6, intervalValue: '30 minutes', intervalNumValue: '30', intervalId: 'THIRTY_MIN' },
];

export const eventTimes = [
  {
    id: 0,
    timeLabel: '8:00 AM',
    timeValue: '8:00',
  },
  {
    id: 1,
    timeLabel: '8:30 AM',
    timeValue: '8:30',
  },
  {
    id: 2,
    timeLabel: '9:00 AM',
    timeValue: '9:00',
  },
  {
    id: 3,
    timeLabel: '9:30 AM',
    timeValue: '9:30',
  },
  {
    id: 4,
    timeLabel: '10:00 AM',
    timeValue: '10:00',
  },
  {
    id: 5,
    timeLabel: '10:30 AM',
    timeValue: '10:30',
  },
  {
    id: 6,
    timeLabel: '11:00 AM',
    timeValue: '11:00',
  },
  {
    id: 7,
    timeLabel: '11:30 AM',
    timeValue: '11:30',
  },
  {
    id: 8,
    timeLabel: '12:00 PM',
    timeValue: '12:00',
  },
  {
    id: 9,
    timeLabel: '12:30 PM',
    timeValue: '12:30',
  },
  {
    id: 10,
    timeLabel: '1:00 PM',
    timeValue: '13:00',
  },
  {
    id: 11,
    timeLabel: '1:30 PM',
    timeValue: '13:30',
  },
  {
    id: 12,
    timeLabel: '2:00 PM',
    timeValue: '14:00',
  },
  {
    id: 13,
    timeLabel: '2:30 PM',
    timeValue: '14:30',
  },

  {
    id: 14,
    timeLabel: '3:00 PM',
    timeValue: '15:00',
  },
  {
    id: 15,
    timeLabel: '3:30 PM',
    timeValue: '15:30',
  },
  {
    id: 16,
    timeLabel: '4:00 PM',
    timeValue: '16:00',
  },
  {
    id: 17,
    timeLabel: '4:30 PM',
    timeValue: '16:30',
  },

  {
    id: 18,
    timeLabel: '5:00 PM',
    timeValue: '17:00',
  },
  {
    id: 19,
    timeLabel: '5:30 PM',
    timeValue: '17:30',
  },

  {
    id: 20,
    timeLabel: '6:00 PM',
    timeValue: '18:00',
  },
  {
    id: 21,
    timeLabel: '6:30 PM',
    timeValue: '18:30',
  },

  {
    id: 22,
    timeLabel: '7:00 PM',
    timeValue: '19:00',
  },
  {
    id: 23,
    timeLabel: '7:30 PM',
    timeValue: '19:30',
  },

  {
    id: 24,
    timeLabel: '8:00 PM',
    timeValue: '20:00',
  },
  {
    id: 25,
    timeLabel: '8:30 PM',
    timeValue: '20:30',
  },
  {
    id: 26,
    timeLabel: '9:00 PM',
    timeValue: '21:00',
  },
  {
    id: 27,
    timeLabel: '9:30 PM',
    timeValue: '21:30',
  },
  {
    id: 28,
    timeLabel: '10:00 PM',
    timeValue: '22:00',
  },
  {
    id: 29,
    timeLabel: '10:30 PM',
    timeValue: '22:30',
  },
];

export const eventStatus = [
  { id: 1, statusValue: 'Scheduled' },
  { id: 2, statusValue: 'Not Scheduled' },
  { id: 3, statusValue: 'Planned' },
  { id: 4, statusValue: 'Cancelled' },
  { id: 5, statusValue: 'OK' },
];
//   {
//     "fullName": "Mary Jones",
//  "firstName": "Mary",
//  "lastName": "Jones",
//  "emailAddress": "test5@body-analytics.com",
//  "phoneNumber": "972-222-1111",
//  "password": "p@s$w0rd11",
//  "role": "USER"
// }

// public eventDetails =0;
// public readonly eventDetails: EventDetailsI = {
//   id: 1,
//   name: 'Crossfit Unmarked',
//   address: {
//     address1: '11551 Louetta Rd.',
//     address2: '',
//     city: 'Houston',
//     state: 'TX',
//     zipcode: '77070',
//   } as AddressI,

//   eventUrl: 'link',
//   eventDate: 'Saturday, January 28',
//   eventTimeStart: '7:00 AM',
//   eventTimeEnd: '12:00 PM',
//   challengeCodes: [
//     {
//       code: 'Challenge 2023',
//     },
//     {
//       code: 'Challenge 2021',
//     },
//   ] as ChallengeCodesI[],
// };
export const mobileTestingServices = [
  {
    id: 1,
    name: 'Body Composition Analysis',
    serviceCode: 'BODYFATANALYSIS',
    serviceDescription: '15 minute analysis',
    price: 59,
    priceFormatted: '$59.00',
  },
];
