export interface RegionsI {
  id: string;
  regionName: string;
  regionLogo: string;
  regionDescription: string;
  regionTerritoryType: string;
  regionActivatedDate: string;
  regionStatus: string;
  createdAt: string;
  updatedAt: string;
}
// "id": "b565af5b-fdf1-47ec-ab7a-d2421983fe94",
// "regionName": "NORTH_TEXAS",
// "regionLogo": "https://loremflickr.com/640/480?lock=3854252841631744",
// "regionDescription": "Angulus vesica cotidie solitudo trepide adstringo cunae thalassinus.",
// "regionTerritoryType": "ABX",
// "regionActivatedDate": "2022-11-19T10:50:24-06:00",
// "regionStatus": "DECOMISSIONED",
// "createdAt": "2023-11-11T15:45:15.593Z",
// "updatedAt": "2023-11-11T15:45:15.593Z"
