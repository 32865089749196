<div class="container">
  @if (userName(); as userName) {
    <div class="row py-4">
      <div class="col-md-12 col-sm-12">
        <h1 class="py-3">Welcome, {{ userName }}!</h1>
        <div class="lead">Find out what you're made of, and commit to your fitness goals!</div>
        <button (click)="downloadLatestResult()" class="mt-3" mat-stroked-button>
          <mat-icon>download</mat-icon>Download latest result
        </button>
      </div>
    </div>

    <div class="row py-4">
      <div class="col-md-8 col-sm-12">
        <h2 class="py-3">Test Results</h2>

        <h4 class="d-flex flex-wrap">
          Weight Time Series Data
          <small style="font-weight: normal; margin-left: auto">
            Lb.
            <mat-slide-toggle
              [(ngModel)]="lbsKg"
              class="mat-mdc-slide-toggle mat-accent mat-mdc-slide-toggle-checked"
              hideIcon />
            Kg.
          </small>
        </h4>

        <div [options]="weightsChart()" echarts></div>

        <h4 class="pt-5">Mass Fraction Time Series Data</h4>

        <div [options]="bodyPercentagesChart()" echarts></div>
      </div>
      <div class="col-md-4 col-sm-12">
        <h4 class="py-3">Your Body Composition</h4>

        <div [options]="bodyCompositionChart()" echarts></div>
      </div>
    </div>

    <div class="row py-4">
      <div class="col-md-12 col-sm-12">
        <h4 class="py-3">Test History</h4>
        <h4 class="lead">Your previous AppliedBioX tests</h4>

        @if (clientHistory(); as history) {
          <div class="table-responsive">
            <table>
              <tr>
                <th>Date</th>
                <th>Height</th>
                <th>Weight</th>
                <th>Lean Lb.</th>
                <th>Fat Lb.</th>
                <th>Water Lb.</th>
                <th>Body Fat %</th>
                <th>Fat %<br />Change</th>
                <th>Weight<br />Change</th>
                <th>Lean Lb.<br />Change</th>
                <th>Fat Lb.<br />Change</th>
                <th>Fat %<br />Diff.</th>
              </tr>
              @for (item of history; track item.id) {
                <tr>
                  <td>{{ item.date }}</td>
                  <td>{{ item.height }}</td>
                  <td>{{ item.dryWeight }}</td>
                  <td>{{ item.leanLbs }}</td>
                  <td>{{ item.fatLbs }}</td>
                  <td>{{ item.waterLbs }}</td>
                  <td>{{ item.fatPercent }}</td>
                  <td>{{ item.fatPercentDiff }}</td>
                  <td>{{ item.weightPercentDiff }}</td>
                  <td>{{ item.leanLbsDiff }}</td>
                  <td>{{ item.fatLbsDiff }}</td>
                  <td>{{ item.bodyFatPercentDiff }}</td>
                </tr>
              }
            </table>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="d-flex justify-content-center">
      <mat-spinner diameter="48" mode="indeterminate" />
    </div>
  }
</div>
