export interface MealPlanI {
  id: string;
}

// export interface WalletCreditCard {
//   nameOnCard: string;
//   cardNumber: number;
//   expirationMonth: number;
//   expirationYear: number;
//   cvc: number;
//   customer: CustomerLocation;
// }
// export interface CustomerLocation {
//   address1: string;
//   address2: string;
//   city: string;
//   state: string;
//   zipCode: string;
// }

export interface CreateMealPlan {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  orderNumber: string;
  orderDate: string;
  deliveryDate: string;

  // amount: number;
  // referralCode: string;
  // creditCard: WalletCreditCard;
}
export interface CreateMealPlanResponse {
  status: string;
}
