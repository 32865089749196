export interface SmsI {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  date: string;
  incomingMessage: string;
  outgoingResponse: string;
}
export interface SmsResponseI {
  id: string;
}
