export interface FormField<T> {
  key: string;
  label: string;
  type: T; //  type: 'text' | 'select' | 'textarea' | 'checkbox' | 'radio' | 'date' | 'number' | 'email' | 'password'; // Include more types as needed
  options?: { value: string; label: string }[]; // For select, radio, checkbox
  placeholder?: string;
  validation?: any[]; // Array of validation functions or validators
  tooltip?: string;
  tooltipPosition?: string; //below, above, left, right
}
