export interface RacesI {
  id: string;
  name: string;
  value: string;
  code: string;
}
export interface EthnicityI {
  id: string;
  name: string;
  value: string;
}

export interface GenderI {
  id: string;
  name: string;
  value: string;
}
export interface SexI {
  id: string;
  name: string;
  value: string;
}
