export interface SettingsI {
  id: string;
  companyName: string;
  city: string;
  state: string;
  mailServer: string;
  port: number;
  mailUsername: string;
  mailPassword: string;
  defaultFromAddrees: string;
  useSsl: boolean;
  lastUpdateDate: string;
  twilioAccountSID: string;
  twilioAuthToken: string;
  smsPhoneNumber: string;
  stripeAPIKey: string;
  stripeAPITestKey: string;
  stripePaymentDescription: string;
  updatedDate: string;
}
