<div class="home-btn d-none d-sm-block">
  <a routerLink=""><i class="mdi mdi-home-variant h2 text-white"></i></a>
</div>
<div>
  <div class="container pt-3">
    <div class="row mb-2">
      <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <h4 class="font-size-18 my-4 text-black">Discover AppliedBioX Membership</h4>
            <!--  -->
            <!-- <h4 class="font-size-18 my-5 text-black">Membership benefits include</h4> -->
            <ul>
              <li>Access to previous fitness tests</li>
              <li>Detailed progress tracking</li>
              <!-- Free macros calculator -->
              <li>Notifications about upcoming events</li>
            </ul>
            <!-- <hr class="my-5" /> -->

          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col mx-auto px-5 py-5 d-flex flex-column position-static">
            <!-- <strong class="d-inline-block mb-2 text-success-emphasis">Design</strong> -->
            <h3 class="mb-5">Access your member account</h3>
            <button type="button" class="btn btn-primary" (click)="auth.loginWithRedirect()">Login / Signup </button>

         <!-- <div class="" *ngIf="!isAdminPortal()">
              <hr class="my-5" />
              <button type="button" class="btn btn-link fw-bold text-left" (click)="signup()">
                Create a member account
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
