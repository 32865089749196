import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[abxPhoneMask]',
})
export class PhoneMaskDirective {
  constructor() {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const cleaned = input.value.replace(/\D+/g, '');
    const match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);

    console.log('phone number:', input.value.length)
    if (match) {
      input.value = `(${match[1]}${match[2] ? ')-' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    } else {
      input.value = '';
    }
  }
}
