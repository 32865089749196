export interface PaymentI {
  id: string;
  amount: number;
}

// "id": "3bf0ef58-b8ec-454a-aa57-d8af5ef18236",
//     "paymentForBodyFatTestAmount": "49",
//     "paymentForMealPlan": "49",
//     "paymentForClubAmount": 0,
//     "paymentTypeId": 133,
//     "paymentRate": "59",
//     "paymentNoteId": null,
//     "isCustomerWalkIn": false,
//     "updatedDate": "2023-11-09T00:18:41.398Z"
