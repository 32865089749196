import { Route, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LoginComponent } from '@bodyanalytics/authentication-ui';
import { dashboardUiRoute } from '@bodyanalytics/dashboard-ui';
import { ServerErrorComponent, homeRoute } from '@bodyanalytics/ui-core';

export const authRoutes: Route[] = [];

export const appRoutes: Route[] = [
  homeRoute,
  { path: '', pathMatch: 'full', redirectTo: 'home' }, // added

  { path: 'login', component: LoginComponent },
  { path: 'error', component: ServerErrorComponent },

  {
    path: 'auth',
    children: authRoutes,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'auth/profile',
  //   loadChildren: () =>
  //     import('@bodyanalytics/profile-ui').then(
  //       mod => mod.ProfileUiModule, // added
  //     ),
  //   canActivate: [AuthGuard], // added
  // },
  dashboardUiRoute,
  // { path: '**', redirectTo: homeRoute.path },
  {
    path: 'access-denied',
    loadComponent: () => import('@bodyanalytics/ui-core').then(mod => mod.AccessDeniedComponent),
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('@bodyanalytics/ui-core').then(mod => mod.PageNotFoundComponent),
  },
  {
    path: 'server-error',
    loadComponent: () => import('@bodyanalytics/ui-core').then(mod => mod.ServerErrorComponent),
  },
] satisfies Routes;
