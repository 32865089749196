import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyanalyticsBaseComponent } from './components/bodyanalytics-base/bodyanalytics-base.component';
import { MainNavComponent } from './shell/main-nav/main-nav.component';
import { NavComponent } from './shell/nav/nav.component';
import { LoginButtonComponent } from './shell/login-button/login-button.component';
import { LogoutButtonComponent } from './shell/logout-button/logout-button.component';
import { SignupButtonComponent } from './shell/signup-button/signup-button.component';
import { MemberButtonComponent } from './shell/member-button/member-button.component';

import { HeaderComponent } from './shell/header/header.component';
import { RouterModule } from '@angular/router';
import { ProfileButtonComponent } from './shell/profile-button/profile-button.component';
import { AdminMemberButtonComponent } from './shell/admin-member-button/admin-button.component';
import { CalendarButtonComponent } from './shell/calendar-button/calendar-button.component';
import { LoadingSpinnerComponent } from './shared-components/loading-spinner/loading-spinner.component';
import { MaterialModule } from './angular-material/material.module';
import { ModalComponent } from './components/modal/modal.component';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import { GenericDashboardComponent } from './components/generic-dashboard/generic-dashboard.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { DataModelsUiModule } from '@bodyanalytics/data-models-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BookingCancelDialogComponent } from './components/dialogs/booking-cancel-dialog/booking-cancel-dialog.component';
import { BookingRescheduleDialogComponent } from './components/dialogs/booking-reschedule-dialog/booking-reschedule-dialog.component';
import { PhoneMaskDirective } from './directives/phone-number.directive';
import { TimerComponent } from './components/timer/timer.component';
import { AmPmPipe } from './pipes/time-format.pipe';
import { FooterComponent } from './shell/footer/footer.component';
import { PhonePipe } from './pipes/phone.pipe';

@NgModule({
  imports: [
    CommonModule,
    GenericFormComponent,
    LoadingSpinnerComponent,
    RouterModule,
    MaterialModule,
    DataModelsUiModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    BodyanalyticsBaseComponent,
    NavComponent,
    MainNavComponent,
    LogoutButtonComponent,
    LoginButtonComponent,
    SignupButtonComponent,
    MemberButtonComponent,
    HeaderComponent,
    ProfileButtonComponent,
    AdminMemberButtonComponent,
    CalendarButtonComponent,
    ModalComponent,
    GenericDashboardComponent,
    GenericTableComponent,
    BookingCancelDialogComponent,
    BookingRescheduleDialogComponent,
    PhoneMaskDirective,
    TimerComponent,
    AmPmPipe, // Add your pipe here
    PhonePipe,
    FooterComponent,
  ],
  exports: [
    BodyanalyticsBaseComponent,
    NavComponent,
    MainNavComponent,
    HeaderComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    PhoneMaskDirective,
    GenericFormComponent,
    GenericDashboardComponent,
    GenericTableComponent,
    BookingCancelDialogComponent,
    BookingRescheduleDialogComponent,
    TimerComponent,
    AmPmPipe, // Add your pipe here
    PhonePipe,
    FooterComponent,
  ],
})
export class UiCoreModule {}
