export interface TruckI {
  id: string | number;
  truckName: string;
  truckCode: string;
  truckLocation?: string;
  truckLocationId: string;
  truckOperatorName?: string;
  truckOperatorId: string;
  truckNote: string;
  truckStatus: string;
  createdAt: string;
  updatedAt: string;
}
