<section>
  <div class="row">
    <div class="col-md-12">
      <div>
        <h1 class="h2 pt-xl-1 pb-3"><i class="bx bx-user-circle" id="sidebarOpen"></i> Create profile</h1>
      </div>
      <bodyanalytics-register-profile-form (submitFormEvent)="update($event)"></bodyanalytics-register-profile-form>
    </div>
  </div>
</section>
