<form
  class="needs-validation"
  novalidate=""
  [formGroup]="securityForm"
  fxLayout="column"
  fxLayoutAlign="center none">


  <div class="row">
    <div class="col-sm-6 mb-2">
      <div class="position-relative">
        <label for="emailAddress" class="form-label fs-base">Email address</label>
        <input
          type="email"
          id="emailAddress"
          [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
          class="form-control form-control-lg"
          placeholder="mary@mail.com"
          maxlength="50"
          formControlName="emailAddress"
          #emailAddress />
        <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
          <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
          <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-2">
      <label for="newPassword" class="form-label fs-base">Password</label>
      <div class="password-toggle">
        <input
          class="form-control form-control-lg"
          [ngClass]="{ 'is-invalid': formsubmit && form.newPassword.errors }"
          placeholder=""
          type="password"
          formControlName="newPassword"
          #newPassword />
        <div class="invalid-tooltip position-absolute top-100 start-0">Incorrect password!</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 mb-2">
      <label for="confirmPassword" class="form-label fs-base">Confirm password</label>
      <div class="password-toggle">
        <input
          class="form-control form-control-lg"
          placeholder=""
          type="password"
          formControlName="confirmPassword"
          #confirmPassword />
        <div class="invalid-tooltip position-absolute top-100 start-0">Incorrect password!</div>
      </div>
    </div>
  </div>
  <div class="d-flex my-5">
    <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="updateSettings()">Save changes</button>
  </div>
</form>
