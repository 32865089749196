import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { differenceInYears } from 'date-fns';

export function minimumAgeValidator(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateOfBirth = control.value;
    if (!dateOfBirth) {
      return null; // return null if no date is provided
    }

    const age = differenceInYears(new Date(), new Date(dateOfBirth));
    return age >= minAge ? null : { minimumAge: { requiredAge: minAge, actualAge: age } };
  };
}
export function atLeastOneCheckboxValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const isRemindByEmail = formGroup.get('isRemindByEmail')?.value;
    const isRemindBySms = formGroup.get('isRemindBySms')?.value;

    if (isRemindByEmail || isRemindBySms) {
      return null; // valid if at least one is true
    } else {
      return { atLeastOneRequired: true }; // invalid if both are false
    };
  }
}
