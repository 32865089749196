import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './containers/registration/registration.component';
import { RegisterInitComponent } from './containers/register-init/register-init.component';
import { RegisterAccountFormComponent } from './components/account-form/account-form.component';
import { RegisterProfileComponent } from './containers/register-profile/register-profile.component';
import { RegisterProfileFormComponent } from './components/profile-form/profile-form.component';
export const registerAccountRoutes: Route[] = [
  {
    path: 'register',
    component: RegistrationComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'account' },
      {
        path: 'account',
        component: RegisterInitComponent,
        pathMatch: 'full',
      },
      // {
      //   path: 'profile',
      //   component: RegisterProfileComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(registerAccountRoutes)],
  declarations: [
    RegistrationComponent,
    RegisterAccountFormComponent,
    RegisterInitComponent,
    RegisterProfileComponent,
    RegisterProfileFormComponent,
  ],
})
export class RegistrationUiModule {}
