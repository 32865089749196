export enum ContentEnum {
  AD_IMAGE_URL = `https://images.pexels.com/photos/1153369/pexels-photo-1153369.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`,
  AD_IMAGE_ALT = `Bootstrap Themes`,
  AD_IMAGE_DESC = `Ads for Nutrition coaching`,

  AD1_IMAGE_URL = `https://images.pexels.com/photos/414029/pexels-photo-414029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`,
  AD1_IMAGE_ALT = ``,
  AD1_IMAGE_DESC = `Metabolism testing with V02 max`,

  AD2_IMAGE_URL = `https://images.pexels.com/photos/3912516/pexels-photo-3912516.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`,
  AD2_IMAGE_ALT = ``,
  AD2_IMAGE_DESC = `Join our growing team!`,

  AD3_IMAGE_URL = `https://media.istockphoto.com/id/1293388093/photo/two-men-exercising.jpg?s=1024x1024&w=is&k=20&c=l4_Sid0SP-o0kxiYY2GIBi4HFVNCfHRF7RgYTa57DAA=`,
  AD3_IMAGE_ALT = ``,
  AD3_IMAGE_DESC = ` Book your next testing session`,

  HOME_HERO_AD_TITLE = `Hydrostatic Body Fat Testing`,
  HOME_HERO_DESCRIPTION = `Fast, Accurate, Body Fat Testing.`,
  HOME_HERO_PRICE = `$59 per visit`,

  HOME_SERVICES_TITLE = ``,
  HOME_SERVICES_DESCRIPTION = ``,
  HOME_SERVICES_IMAGE = ``,
  HOME_SERVICES_BUTTON_LABEL = ``,

  HOME_SERVICES1_TITLE = ``,
  HOME_SERVICES1_DESCRIPTION = ``,
  HOME_SERVICES1_IMAGE = ``,
  HOME_SERVICES1_BUTTON_LABEL = ``,

  HOME_SERVICES2_TITLE = ``,
  HOME_SERVICES2_DESCRIPTION = ``,
  HOME_SERVICES2_IMAGE = ``,
  HOME_SERVICES2_BUTTON_LABEL = ``,

  HOME_SERVICES3_TITLE = ``,
  HOME_SERVICES3_DESCRIPTION = ``,
  HOME_SERVICES3_IMAGE = ``,
  HOME_SERVICES3_BUTTON_LABEL = ``,

  HOME_Testimonials1_TITLE = ``,
  HOME_Testimonials1_DESCRIPTION = ``,
  HOME_Testimonials1_IMAGE = ``,

  HOME_Testimonials2_TITLE = ``,
  HOME_Testimonials2_DESCRIPTION = ``,
  HOME_Testimonials2_IMAGE = ``,

  HOME_Testimonials3_TITLE = ``,
  HOME_Testimonials3_DESCRIPTION = ``,
  HOME_Testimonials3_IMAGE = ``,

  HOME_FAQ1_TITLE = ``,
  HOME_FAQ1_DESCRIPTION = ``,
  HOME_FAQ1_IMAGE = ``,

  HOME_FAQ2_TITLE = ``,
  HOME_FAQ2_DESCRIPTION = ``,
  HOME_FAQ2_IMAGE = ``,

  HOME_FAQ3_TITLE = ``,
  HOME_FAQ3_DESCRIPTION = ``,
  HOME_FAQ3_IMAGE = ``,

  HOME_FAQ4_TITLE = ``,
  HOME_FAQ4_DESCRIPTION = ``,
  HOME_FAQ4_IMAGE = ``,

  HOME_FAQ5_TITLE = ``,
  HOME_FAQ5_DESCRIPTION = ``,
  HOME_FAQ5_IMAGE = ``,

  HOME_FAQ6_TITLE = ``,
  HOME_FAQ6_DESCRIPTION = ``,
  HOME_FAQ6_IMAGE = ``,

  HOME_FORM_TITLE = ``,
  HOME_FORM_DESCRIPTION = ``,

  BRINGUSTOYOU_PROMO_TITLE = ``,
  BRINGUSTOYOU_PROMO_CONTENT = ``,
  BRINGUSTOYOU_PROMO_IMAGE = ``,
  BRINGUSTOYOU_PROMO_BTN_LABEL = ``,

  BRINGUSTOYOU_PROMO1_TITLE = ``,
  BRINGUSTOYOU_PROMO1_CONTENT = ``,
  BRINGUSTOYOU_PROMO1_IMAGE = ``,
  BRINGUSTOYOU_PROMO1_BTN_LABEL = ``,

  BRINGUSTOYOU_PROMO2_TITLE = ``,
  BRINGUSTOYOU_PROMO2_CONTENT = ``,
  BRINGUSTOYOU_PROMO2_IMAGE = ``,
  BRINGUSTOYOU_PROMO2_BTN_LABEL = ``,

  WORKFORUS_PROMO_TITLE = ``,
  WORKFORUS_PROMO_CONTENT = ``,
  WORKFORUS_PROMO_IMAGE = ``,
  WORKFORUS_PROMO_BTN_LABEL = ``,

  WORKFORUS_PROMO1_TITLE = ``,
  WORKFORUS_PROMO1_CONTENT = ``,
  WORKFORUS_PROMO1_IMAGE = ``,
  WORKFORUS_PROMO1_BTN_LABEL = ``,

  WORKFORUS_PROMO2_TITLE = ``,
  WORKFORUS_PROMO2_CONTENT = ``,
  WORKFORUS_PROMO2_IMAGE = ``,
  WORKFORUS_PROMO2_BTN_LABEL = ``,

  WORKFORUS_FORM_TITLE = ``,
  WORKFORUS_FORM_DESCRIPTION = ``,
  WORKFORUS_FORM_FORM_BTN_LABEL = ``,

  //
  ABOUT_PROMO_TITLE = ``,
  ABOUT_PROMO_CONTENT = ``,
  ABOUT_PROMO_IMAGE = ``,
  ABOUT_PROMO_BTN_LABEL = ``,

  ABOUT_PROMO1_TITLE = ``,
  ABOUT_PROMO1_CONTENT = ``,
  ABOUT_PROMO1_IMAGE = ``,
  ABOUT_PROMO1_BTN_LABEL = ``,

  ABOUT_PROMO2_TITLE = ``,
  ABOUT_PROMO2_CONTENT = ``,
  ABOUT_PROMO2_IMAGE = ``,
  ABOUT_PROMO2_BTN_LABEL = ``,

  CONTACT_PROMO_TITLE = ``,
  CONTACT_PROMO_CONTENT = ``,
  CONTACT_PROMO_IMAGE = ``,
  CONTACT_PROMO_BTN_LABEL = ``,

  CONTACT_PROMO1_TITLE = ``,
  CONTACT_PROMO1_CONTENT = ``,
  CONTACT_PROMO1_IMAGE = ``,
  CONTACT_PROMO1_BTN_LABEL = ``,

  CONTACT_PROMO2_TITLE = ``,
  CONTACT_PROMO2_CONTENT = ``,
  CONTACT_PROMO2_IMAGE = ``,
  CONTACT_PROMO2_BTN_LABEL = ``,

  DASHBOARD_TITLE = ``,
  DASHBOARD_CONTENT = ``,
  DASHBOARD_IMAGE = ``,
  DASHBOARD_BTN_LABEL = ``,

  DASHBOARD_PROMO_TITLE = ``,
  DASHBOARD_PROMO_CONTENT = ``,
  DASHBOARD_PROMO_IMAGE = ``,
  DASHBOARD_PROMO_BTN_LABEL = ``,

  DASHBOARD_PROMO1_TITLE = ``,
  DASHBOARD_PROMO1_CONTENT = ``,
  DASHBOARD_PROMO1_IMAGE = ``,
  DASHBOARD_PROMO1_BTN_LABEL = ``,

  DASHBOARD_PROMO2_TITLE = ``,
  DASHBOARD_PROMO2_CONTENT = ``,
  DASHBOARD_PROMO2_IMAGE = ``,
  DASHBOARD_PROMO2_BTN_LABEL = ``,

  CALENDAR_TITLE = `Mobile Testing Event Search`,
  CALENDAR_CONTENT = `Find a mobile testing event near you.`,
  CALENDAR_IMAGE = ``,
  CALENDAR_BTN_LABEL = ``,

  CALENDAR_SEARCH_TITLE = `Search events by region`,
  CALENDAR_SEARCH_CONTENT1 = `Mobile testing events in North Texas`,
  CALENDAR_SEARCH_CONTENT2 = `Click the Signup button, if available, to sign up online or contact the gym to sign up.`,
  CALENDAR_SEARCH_CONTENT3 = `Mobile testing events to choose from nearby:`,

  CALENDAR_SEARCH_IMAGE = ``,
  CALENDAR_SEARCH_BTN_LABEL = ``,

  // ABOUT_PROMO_TITLE=``,
  // ABOUT_PROMO_CONTENT=``,
  // ABOUT_PROMO_IMAGE=``,
  // ABOUT_PROMO_BTN_LABEL=``,

  // ABOUT_PROMO1_TITLE=``,
  // ABOUT_PROMO1_CONTENT=``,
  // ABOUT_PROMO1_IMAGE=``,
  // ABOUT_PROMO1_BTN_LABEL=``,

  // ABOUT_PROMO2_TITLE=``,
  // ABOUT_PROMO2_CONTENT=``,
  // ABOUT_PROMO2_IMAGE=``,
  // ABOUT_PROMO2_BTN_LABEL=``,
}
