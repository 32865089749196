<table class="table table-hover">
  <thead class="table-light">
    <tr>
      <th *ngFor="let column of columns" (click)="onColumnClick(column)">
        {{ column.label }}
        <span *ngIf="column.tooltip" matTooltip="{{ column.tooltip }}"> ? </span>
      </th>
    </tr>
  </thead>
  <tbody class="table-group-divider">
    <tr *ngFor="let row of rows; let i = index">
      <td *ngFor="let column of columns">
        <!-- <span *ngIf="column.key === 'id'">
          <input type="checkbox" [value]="row.data.id" (change)="onCheckboxChange($event)" />
        </span> -->
        <span *ngIf="column.key === 'date'">
          {{ row.data[column.key] | date : 'shortDate' }}
        </span>
        <span *ngIf="column.key !== 'date' && column.key !== 'id'">
          {{ row.data[column.key] }}
        </span>
      </td>
      <td>
        <button type="button" class="btn" (click)="onEditRow(row)">
          <i class="bx bx-pencil"></i>
        </button>
        <button type="button" class="btn" (click)="onDeleteRow(i)">
          <i class="bx bx-task-x"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
