import { AuthConfig } from '@auth0/auth0-angular';

export interface Env {
  api: string;
  production: boolean;
  auth0: AuthConfig;
  calendarUrl: string;
  stripePKey:string;
  portalName:string;
  analyticsMeasurementId:string;
  stripe_package_price_1:string;
  stripe_package_price_2:string;
  stripe_package_price_3:string;
  stripe_package_price_4:string;
  stripe_package_price_5:string;
  stripe_package_price_6:string;
  stripe_package_price_7:string;
}
