import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bodyanalytics-register-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class RegisterAccountFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  formsubmit = false;
  constructor() {
    //
  }

  public securityForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      emailAddress: this.securityForm.value.emailAddress as string,
      password: this.securityForm.value.newPassword as string,
      confirmPassword: this.securityForm.value.newPassword,//this.securityForm.value.confirmPassword as string,
      role: 'Customer'
    });
  }
  /**
   * Returns form
   */
  get form() {
    return this.securityForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
