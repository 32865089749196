export interface CurrentStatusGoalsI {
  id: number;
  ageLow: number;
  percent: number;
  percentile: number;
  ageHigh: number;
  value1: number;
  value: number;
  value2: number;
  value3: number;
  value4: number;
  value5: number;
  rating: string;
  tempfield_0: string;
  gender: string;
}
