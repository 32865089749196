export interface DiscountI {
  id: string;
  discountCode: string;
  discountType?: string;
  discountAmount?: number;
  discountExpireDate?: string;
  discountContactId?: string | null;
  discountContactName?: string | null;
  discountContactEmail?: string | null;
  discountForEventId?: string | null;
  isCustomerDiscount?: boolean;
  discountDescription?: string | null;
  discountForTrainerId?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export enum DiscountTypesEnum {
  ONE_TIME = 'One Time',
  TEST = 'Test',
  MEAL_PLAN = 'Meal Plan',
}
