import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs';
import { decodeJwt } from 'jose';
import { JWTPayload as JWTPayload_ } from 'express-oauth2-jwt-bearer';

export interface JWTPayload extends JWTPayload_ {
  permissions: string[];
}

export const authPermissionsGuard: CanMatchFn = route => {
  const router = inject(Router);
  const { data } = route;

  return inject(AuthService)
    .getAccessTokenSilently()
    .pipe(
      map<string, JWTPayload>(decodeJwt),
      map<JWTPayload, boolean | UrlTree>((jwt: JWTPayload) => {
        if (data && 'permissions' in data && Array.isArray(data['permissions'])) {
          return data['permissions']?.every(p => jwt.permissions.includes(p));
        }

        return router.createUrlTree(['/access-denied']);
      }),
    );
};
