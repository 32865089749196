import { CustomerI } from './customer';
import { OrderI } from './orders';
import { ProductI } from './products';
import { ServicesI } from './services';
import { SignupFormI } from './singup';
import { PromoCodeI, SlotI, SlotNI, TestingEventI } from './testing-events';

export interface BookingRescI {
  event: TestingEventI;
  booking: BookingI;
}
export interface ReschBookingDataI {
  selectedTimeSlot: Date | null;
  selectedEvent: TestingEventI | null;
  existingBooking: BookingI | null;
  existingOrderId: string | null;
}

export interface CreateRescheduleI {
  existingBookingId: string;
  existingOrderId: string;
  newEventId: string;
  newEventName: string;
  testEventDate: string;
  slotTime: string;
}
export interface BookingDataI {
  selectedTime: Date | null;
  selectedTimeSlot: SlotNI | null;
  selectedSlot: SlotNI | null;
  selectedEvent: TestingEventI | null;
  savedCustomerData: CustomerI | null;
  selectedProduct: ProductI | null;
  selectedService: ServicesI | null;
  userInfo: SignupFormI | null;
  bookingTotalWithTaxServiceFee: number;
  orderTotal: OrderTotalsI | null;
  promotion: PromoCodeI | null;
  workflowStep: string;
}
export interface OrderTotalsI{
  subtotal: number;
  salesTax: number;
  total: number;
  serviceFee: number;
  promocode: string;
}

export interface BookingI {
  id: string;
  name: string;
  date: string;
  slotTime: string;
  clubName: string;
  clubAddress: string;
  createdAt: string;
  slotId: string;
  clubId: string;
  eventId: string;
  serviceName: string;
  num: number;
  isAvailable: boolean;
  status: string;
  isSelected: boolean;
  isPaid:boolean;
  isCancelled:boolean;
  customer: CustomerI;
  event: TestingEventI;
  slot: SlotI;
  order: OrderI[]
}
