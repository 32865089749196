import { Injectable } from '@angular/core';
import { enUS } from 'date-fns/locale';
// import { format, parseISO } from 'date-fns';

import { format, utcToZonedTime,zonedTimeToUtc } from 'date-fns-tz';

@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {
  public formatTimeForDisplay(time: Date) {
    // Create a Date object from the input string
    const date = new Date(time);
    const timeZone = 'America/Chicago';
    const zonedDate = utcToZonedTime(date, timeZone);

   // Convert the time to UTC first to neutralize the input timezone offset in the string.
  //  const utcTime = zonedTimeToUtc(time, timeZone);
    // Convert to UTC by using the toISOString method, which returns a string in simplified extended ISO format (ISO 8601)
    // which is always 0 UTC, denoted by the suffix "Z".
    //const utcTime = date.toISOString();
    const utcTime = date.toISOString();
    console.log('zonedDate:',zonedDate);
    // console.log(utcTime)
    // Then convert this UTC time to the desired (output) timezone.
    // const zonedTime = utcToZonedTime(utcTime, timeZone);

    // Return the formatted time.
    // return format(zonedTime, 'h:mm a', { timeZone: timeZone });
    // return format(utcTime, 'h:mm a', { timeZone });


    // const timeSlot = format(date, 'h:mm a', { timeZone: timeZone, locale: enUS });
   return utcTime;
  }
}
