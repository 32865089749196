<bodyanalytics-header></bodyanalytics-header>
<main class="col-12">
  <div class="container" *ngIf="auth.isLoading$ | async; else loaded">
    <div class="row">
      <bodyanalytics-loading-spinner></bodyanalytics-loading-spinner>
    </div>
  </div>

  <ng-template #loaded>
    <div class="flex-grow-1 mx-auto">
      <div class="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-template>
</main>
<bodyanalytics-footer/>
