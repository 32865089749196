import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedLogService {
  constructor() {
    //
  }

  public log(message: string) {
    console.log(message);
    // this.messageService.add(`ManageEmailService: ${message}`)
  }
}
