export interface LoginI {
  emailAddress: string;
  password?: string;
}


export interface RegistrationI{
  emailAddress: string;
  password: string;
  confirmPassword: string;
  role:string
}
