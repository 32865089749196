import { Component } from '@angular/core';
import { DialogDataEnum } from '../../../enums/dialog.enum';

@Component({
  selector: 'bodyanalytics-booking-cancel-dialog',
  templateUrl: './booking-cancel-dialog.component.html',
  styleUrls: ['./booking-cancel-dialog.component.scss'],
})
export class BookingCancelDialogComponent {
  protected dialogEnum = DialogDataEnum;
}
