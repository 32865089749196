<form class="row g-3" [formGroup]="securityForm" (ngSubmit)="formSubmit()">
  <div>
    <div class="row pb-2">
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="firstName" class="form-label fs-base">First Name</label>
          <input
            type="text"
            id="firstName"
            class="form-control form-control-lg"
            formControlName="firstName"
            [ngClass]="{ 'is-invalid': formsubmit && form.firstName.errors }" />
          <div *ngIf="formsubmit && form.firstName.errors" class="invalid-tooltip">
            <span *ngIf="form.firstName.errors['required']">First name is required.</span>
            <!-- <span *ngIf="form.firstName.errors['pattern']">This value should be a valid email.</span> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="lastName" class="form-label fs-base">Last Name</label>
          <input
            type="text"
            id="lastName"
            class="form-control form-control-lg"
            formControlName="lastName"
            [ngClass]="{ 'is-invalid': formsubmit && form.lastName.errors }" />
          <div *ngIf="formsubmit && form.lastName.errors" class="invalid-tooltip">
            <span *ngIf="form.lastName.errors['required']">Last name is required.</span>
            <!-- <span *ngIf="form.firstName.errors['pattern']">This value should be a valid email.</span> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="emailAddress" class="form-label fs-base">Email Address</label>
          <input
            type="email"
            id="emailAddress"
            [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
            class="form-control form-control-lg"
            formControlName="emailAddress" />
          <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
            <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
            <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="phoneNumber" class="form-label fs-base">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            class="form-control form-control-lg"
            formControlName="phoneNumber"
            [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }" />
          <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
            <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="genderDropdown" class="form-label fs-base">Gender</label>
          <select
            id="genderDropdown"
            class="form-control form-select form-select-lg"
            name="gender"
            formControlName="gender"
            [ngClass]="{ 'is-invalid': formsubmit && form.gender.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let gender of genderList" [value]="gender.id">
              {{ gender.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.gender.errors" class="invalid-tooltip">
            <span *ngIf="form.gender.errors['required']">Gender is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="birthDate" class="form-label fs-base">Date of Birth</label>
          <input
            type="date"
            id="birthDate"
            class="form-control form-control-lg"
            formControlName="birthDate"
            [ngClass]="{ 'is-invalid': formsubmit && form.birthDate.errors }" />
          <div *ngIf="formsubmit && form.birthDate.errors" class="invalid-tooltip">
            <span *ngIf="form.birthDate.errors['required']">Date of birth is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="ethnicityDropdown" class="form-label fs-base">Ethnicity</label>

          <select
            id="ethnicityDropdown"
            class="form-control form-select form-select-lg"
            name="ethnicity"
            formControlName="ethnicity"
            [ngClass]="{ 'is-invalid': formsubmit && form.ethnicity.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let ethnic of ethnicityList" [value]="ethnic.id">
              {{ ethnic.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.ethnicity.errors" class="invalid-tooltip">
            <span *ngIf="form.ethnicity.errors['required']">Ethnicity is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="race" class="form-label fs-base">Race</label>
          <select
            id="raceDropdown"
            class="form-control form-select form-select-lg"
            name="race"
            formControlName="race"
            [ngClass]="{ 'is-invalid': formsubmit && form.race.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let race of raceList" [value]="race.id">
              {{ race.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.race.errors" class="invalid-tooltip">
            <span *ngIf="form.race.errors['required']">Race is required.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-3">
      <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="createProfile()">Complete</button>
    </div>
  </div>
</form>
