import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-member-button',
  templateUrl: './member-button.component.html',
  styleUrls: ['./member-button.component.scss'],
})
export class MemberButtonComponent {
  constructor(private router: Router) {}
  dashboard() {
    //create a register route
    this.router.navigate([`${RouterUrlsEnum.AUTH_DASHBOARD}`]);
    //route to signup page
    // let users find themselves in our db
    // ask user questions to link their account
    // if user provides the right information, help them create a profile
    //ask survey questions
    // do not show dashboard if we don't have any test data
  }
}
