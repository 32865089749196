import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-signup-button',
  templateUrl: './signup-button.component.html',
})
export class SignupButtonComponent {
  protected registerLink = RouterUrlsEnum.AUTH_REGISTER_ACCOUNT;
  constructor(private router: Router) {}

  public register(){
    // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_REGISTER_ACCOUNT}`);
    this.router.navigate([`${this.registerLink}`]);
  }
}
