export interface CreateEmailI {
  id?: string;
  date: string;
  emailSubject: string;
  emailMessage: string;
}

export interface EmailI {
  emailContent: string;
  emailSubject: string;
  emailTo: string;
}
export interface EmailResponseI {
  id: string;
}
