export enum ApiLocalRoutesEnum {
  API_URL = 'http://localhost',
  DEV_API_URL = 'https://dev.backendapi.appliedbiox.com/api/',
  API_PORT = ':3000/api/',
  API_URL_COMPLETE = 'https://dev.backendapi.appliedbiox.com/api/', //http://localhost:3000/api/
  API_SUFFIX_LOGIN = 'customer/login',
  API_SUFFIX_LOGOUT = 'customer/logout',

  API_SUFFIX_BODY_MEASUREMENTS = 'manage-bodymeasurements-api',
  API_SUFFIX_BODY_MEASUREMENTS_DETAILS = 'manage-bodymeasurements-api/details',
  API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_PROCESS = 'manage-bodymeasurements-api/test-session/process',
  API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_REVIEW = 'manage-bodymeasurements-api/test-session/review-test',
  API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_COMPLETE = 'manage-bodymeasurements-api/test-session/complete-test',
  API_SUFFIX_BODY_MEASUREMENTS_COMPLETE = 'manage-bodymeasurements-api/test-session/completed-tests',
  API_SUFFIX_BODY_MEASUREMENTS_COMPLETE_ALL = 'manage-bodymeasurements-api/all',
  API_SUFFIX_BODY_MEASUREMENTS_HISTORY = 'manage-bodymeasurements-api/test-history',

  API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_REPORT = 'manage-bodymeasurements-api/test-session/report-test',

  API_SUFFIX_MANAGE_REPORTS_API = 'manage-reports-api',

  API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION = 'manage-bodymeasurements-api/test-session/active',
  API_SUFFIX_BODY_MEASUREMENTS_CHECK_IN = 'manage-bodymeasurements-api/test-session/check-in',
  API_SUFFIX_BODY_MEASUREMENTS_DETAILS_SOFT = 'manage-bodymeasurements-api/calculate/test-session/soft',

  API_SUFFIX_OPERATORS = 'manage-operators-api',
  API_SUFFIX_OPERATORS_DETAILS = 'manage-operators-api/details',

  API_SUFFIX_CHECKOUT_TOTAL = 'checkout-api/total',
  API_SUFFIX_STRIPE_CHECKOUT_PAYMENT_INTENT = 'checkout-api/create-payment-intent',

  API_SUFFIX_CLUBS = 'manage-clubs-api',
  API_SUFFIX_CLUBS_DETAILS = 'manage-clubs-api/details',

  API_SUFFIX_CUSTOMERS = 'manage-customers-api',
  API_SUFFIX_CUSTOMERS_DETAILS = 'manage-customers-api/details',

  API_SUFFIX_MEALPLANS = 'manage-mealplans-api',
  API_SUFFIX_MEALPLANS_DETAILS = 'manage-mealplans-api/details',

  API_SUFFIX_PUB_TEST_EVENTS = 'events-api',
  API_SUFFIX_PUB_TEST_EVENTS_DETAILS = 'events-api/public-events/details',
  API_SUFFIX_PUB_TEST_EVENTS_AVAILABILE_SLOTS = 'events-api/public-events/available-slots',
  API_SUFFIX_PUB_TEST_EVENTS_PRODUCTS = 'events-api/public-events/products',
  API_SUFFIX_PUB_TEST_EVENTS_STATES = 'events-api/us-states',

  API_SUFFIX_PUB_CLUB = 'events-api',
  API_SUFFIX_PUB_TEST_CLUB_DETAILS = 'events-api/details',

  API_SUFFIX_TEST_EVENTS = 'manage-events-api',
  API_SUFFIX_TEST_EVENTS_LIST = 'manage-events-api/event/list',

  API_SUFFIX_TEST_EVENTS_AVAILABILE_SLOTS = 'manage-events-api/available-slots',
  API_SUFFIX_TEST_EVENTS_RESERVE_SLOTS = 'manage-events-api/reserve-slots',

  API_SUFFIX_TEST_EVENTS_ALL_SLOTS = 'manage-events-api/all-slots',
  API_SUFFIX_TEST_EVENTS_GENERATE_SLOTS = 'manage-events-api/generate-slots',

  API_SUFFIX_TEST_EVENTS_DETAILS = 'manage-events-api/details',
  API_SUFFIX_TEST_EVENTS_CANCEL_EVENT = 'manage-events-api/cancel-event',

  API_SUFFIX_EMAILS = 'manage-emails-api',
  API_SUFFIX_EMAILS_DETAILS = 'manage-emails-api/details',

  API_SUFFIX_DISCOUNTS = 'manage-discounts-api',
  API_SUFFIX_DISCOUNTS_VALIDATE = 'manage-discounts-api/validate',

  API_SUFFIX_DISCOUNTS_DETAILS = 'manage-discounts-api/details',

  API_SUFFIX_DETAILS = '/details/',

  API_SUFFIX_MIGRATION = 'migration-api',


  API_SUFFIX_BOOKING = 'manage-booking-api',
  API_SUFFIX_BOOKING_SEARCH = 'manage-booking-api/search',

  API_SUFFIX_BOOKING_DETAILS = 'manage-booking-api/details',
  API_SUFFIX_BOOKING_EVENT_LIST = 'manage-booking-api/event/list',


  API_SUFFIX_PAYMENTS = 'manage-payments-api',
  API_SUFFIX_PAYMENTS_DETAILS = 'manage-payments-api/details',
  API_SUFFIX_STRIPE_CREATE_PAYMENT_INTENT = 'manage-payments-api/create-payment-intent',

  API_SUFFIX_PAYMENTS_BOOKING = 'manage-booking-api/process-booking',
  API_SUFFIX_PROCESS_BOOKING_CREDIT = 'manage-booking-api/process-booking-credit',

  API_SUFFIX_PAYMENTS_BOOKING_RESCHEDULE = 'manage-booking-api/reschedule-booking',

  // API_SUFFIX_BOOKING_DETAILS = 'manage-booking-api/details',

  API_SUFFIX_PROFILE = '/profile',
  API_SUFFIX_PROFILE_USER = '/profile/user/',
  API_SUFFIX_PROFILE_ADDRESS = '/profile/address',
  API_SUFFIX_PROFILE_SECURITY = '/profile/security',
  API_SUFFIX_PROFILE_ACCOUNT = '/profile/account',
  API_SUFFIX_PROFILE_NOTIFICATIONS = '/profile/notifications',
  API_SUFFIX_PROFILE_CUSTOMER = '/customer/profile',

  API_SUFFIX_TEST_EVENTS_EVENT = '/events/',
  API_SUFFIX_TEST_EVENTS_ADDRESS = '/events/address',
  API_SUFFIX_TEST_EVENTS_SEARCH = 'manage-events-api/search',

  API_SUFFIX_TEST_EVENTS_SEARCH_GYM = 'manage-events-api/search/gymName',

  API_SUFFIX_SIGNUP_SEARCH = 'manage-signups-api',
  API_SUFFIX_SIGNUP_SETTINGS = 'manage-signups-api/configure',

  API_SUFFIX_SIGNUP_DETAILS = 'manage-signups-api/details',
  API_SUFFIX_SIGNUP_AVAILABILITY = 'manage-signups-api/signup-availability',

  API_SUFFIX_REGISTER = 'registration-api/register',

  API_SUFFIX_USERS = 'manage-users-api',
  API_SUFFIX_USERS_DETAILS = 'manage-users-api/details',

  API_SUFFIX_SETTINGS = 'manage-settings-api',
  API_SUFFIX_SETTINGS_DETAILS = 'manage-settings-api/details',

  API_SUFFIX_ORDERS = 'manage-orders-api',
  API_SUFFIX_ORDERS_DETAILS = 'manage-orders-api/details',

  API_SUFFIX_PRODUCTS_PUBLIC = 'manage-products-api/public/stripe/products',
  API_SUFFIX_PRODUCTS = 'manage-products-api/stripe/products',
  API_SUFFIX_PRODUCTS_DETAILS = 'manage-products-api/stripe/products',
  API_SUFFIX_PRODUCTS_SERVICES = 'manage-products-api/services',

  API_SUFFIX_REGIONS = 'manage-regions-api',
  API_SUFFIX_REGIONS_DETAILS = 'manage-regions-api/details',

  API_SUFFIX_SMS = 'manage-sms-api',
  API_SUFFIX_SMS_DETAILS = 'manage-sms-api/details',

  API_SUFFIX_TRUCKS = 'manage-trucks-api',
  API_SUFFIX_TRUCKS_STATUS = 'manage-trucks-api/truck-status',

  API_SUFFIX_TRUCKS_DETAILS = 'manage-trucks-api/details',
}
