export interface ChartType {
  [x: string]: any;
  series?: any;
  plotOptions?: any;
  stroke?: any;
  dataLabels?: any;
  chart?: any;
  legend?: any;
  colors?: any;
  labels?: any;
}

export interface EChartType {
  xAxis?: any;
  yAxis?: any;
  series?: any;
  color?: any;
  tooltip?: any;
  grid?: any;
  legend?: any;
  animationEasing?: any;
  dataZoom?: any;
  toolbox?: any;
}

export interface Stat {
  title: string;
  icon: string;
  value: string;
}

export interface Chat {
  id?: number;
  name?: string;
  message?: string;
  image?: string;
  time?: string;
  align?: string;
  text?: string;
}

export interface Transaction {
  orderid: string;
  date: string;
  billingname: string;
  total: string;
  paymentstatus: string;
}
