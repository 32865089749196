export enum HttpHeaderEnum {
  SHOW_SPINNER = 'show-spinner',
  httAUTHORIZATION = 'Authorization',
  APIM = 'Ocp-Apim-Subscription-Key',
  CONTENT_TYPE = 'Content-Type',
  TIME_STAMP = 'timestamp',
}

export enum HttpRestMethodEnum {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
}
