import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf, NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-generic-form',
  standalone: true,
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
  imports: [ReactiveFormsModule, NgForOf, NgIf, NgClass, MatTooltipModule],
})
export class GenericFormComponent<T> {
  genericFormGroup!: FormGroup;
  @Input()
  fields: any[] = [];
  @Output() formSubmitted = new EventEmitter<any>();
  public isFormSubmitted = false; // Track form submission for error display

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.genericFormGroup = this.formBuilder.group({});
    this.fields.forEach(field => {
      if (field.type === 'text' || field.type === 'email' || field.type === 'password') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else if (field.type === 'textarea') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else if (field.type === 'select') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else if (field.type === 'radio') {
        this.genericFormGroup.addControl(field.key, this.formBuilder.array([]));
      } else if (field.type === 'checkbox') {
        this.genericFormGroup.addControl(field.key, this.formBuilder.array([]));
      } else if (field.type === 'date') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else if (field.type === 'time') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else if (field.type === 'number') {
        this.genericFormGroup.addControl(field.key, new FormControl(null, field.validation || []));
      } else {
        // Handle other field types as needed
      }
      // this.genericFormGroup.addControl(
      //   field.key,
      //   field.type === 'text' ? new FormControl(null, Validators.required) : new FormControl(null),
      // );
    });
    // Dynamically create form controls based on input field properties
  }

  onSubmit() {
    this.isFormSubmitted = true; // Track form submission for error display

    // Handle form submission here
    // console.log(this.genericFormGroup.value); // Access form data
    // Submit validated form data to service

    if (this.genericFormGroup.valid) {
      const formData = this.genericFormGroup.value;
      this.formSubmitted.emit(formData); // Emit form data

      // Perform actions with form data, such as:
      // - Send data to a server
      // - Trigger other events
      // - Store data locally
      this.isFormSubmitted = false; // Reset for subsequent submissions

      console.log('Form data:', formData);
    } else {
      // Handle invalid form submission
      // console.error('Form is invalid');
    }
  }
}
