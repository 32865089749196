import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouterOutlet } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { UiCoreModule } from '@bodyanalytics/ui-core';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { ENV } from '@bodyanalytics/app-config';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { RegistrationUiModule } from '@bodyanalytics/registration-ui';
import { AuthenticationUiModule } from '@bodyanalytics/authentication-ui';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    UiCoreModule,
    RegistrationUiModule,
    AuthenticationUiModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    RouterOutlet,
  ],

  providers: [
    { provide: ENV, useValue: environment },

    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          `${environment.api}/*`,
         ],
      },
    }),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
