// Angular
import { Injectable } from '@angular/core';

// Models
import { SessionStorageKeyEnum } from './session-storage';
import {
  BookingDataI,
  BookingI,
  CustomerI,
  OrderTotalsI,
  ProductI,
  PromoCodeI,
  ReschBookingDataI,
  ServicesI,
  SignupFormI,
  SlotNI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly sessionStorageEnum = SessionStorageKeyEnum;
  public readonly bookingReservationKey = this.sessionStorageEnum.BOOKING_TOKEN;
  public readonly rescheduleBookingReservationKey = this.sessionStorageEnum.RESCHEDULE_TOKEN;

  constructor() {}

  saveRescheduleBookingData(
    existingBookingData: BookingI | null,
    existingOrderId: string | null,
    newEvent: TestingEventI | null,
    newTimeSlot: Date | null,
  ) {
    const bookingData: ReschBookingDataI = {
      // selectedTimeSlot: newTimeSlot,
      existingBooking: existingBookingData,
      existingOrderId:existingOrderId,
      selectedEvent: newEvent,
      selectedTimeSlot: newTimeSlot,
    };

    sessionStorage.setItem(this.rescheduleBookingReservationKey, JSON.stringify(bookingData));
  }

  getRescheduleFlag(): boolean {
    const dataJson = sessionStorage.getItem(this.rescheduleBookingReservationKey);
    return dataJson ? true : false;
  }

  getRescheduleBookingData(): ReschBookingDataI | null {
    const dataJson = sessionStorage.getItem(this.rescheduleBookingReservationKey);
    return dataJson ? JSON.parse(dataJson) : null;
  }

  clearRescheduleBookingData(): void {
    sessionStorage.removeItem(this.rescheduleBookingReservationKey);
  }

  saveBookingData(
    timeSlot: Date | null,
    product: ProductI,
    service: ServicesI | null,
    userInfo: SignupFormI | null,
    bookingTotalWithTaxServiceFee: number,
    selectedSlot: SlotNI | null,
    selectedEvent: TestingEventI | null,
    customerData: CustomerI | null,
    orderTotal: OrderTotalsI | null,
    promotion: PromoCodeI | null,
    workflowStep: string
  ): void {
    const BookingDataI: BookingDataI = {
      selectedTime: timeSlot,
      selectedProduct: product,
      selectedService: service,
      userInfo: userInfo,
      bookingTotalWithTaxServiceFee: bookingTotalWithTaxServiceFee,
      selectedSlot: selectedSlot,
      selectedEvent: selectedEvent,
      savedCustomerData: customerData,
      selectedTimeSlot: null,
      orderTotal: orderTotal,
      promotion: promotion,
      workflowStep:workflowStep
    };
    sessionStorage.setItem(this.bookingReservationKey, JSON.stringify(BookingDataI));
  }

  getBookingData(): BookingDataI | null {
    const dataJson = sessionStorage.getItem(this.bookingReservationKey);
    return dataJson ? JSON.parse(dataJson) : null;
  }

  clearBookingData(): void {
    sessionStorage.removeItem(this.bookingReservationKey);
  }
  public clear(): void {
    sessionStorage.clear();
  }
}
