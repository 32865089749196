// chart-options.interface.ts
export interface ChartDataI {
  xAxis: any; // Adjust based on your specific xAxis data structure
  yAxis: any; // Adjust based on your specific yAxis data structure
  series: ChartSeriesI[];
}

export interface ChartSeriesI {
  name: string;
  type: string;
  data: number[];
  // ... other properties as needed
}
