<div class="loading-spinner-container">
  <div class="loading-container mb-5">
    <mat-progress-spinner class="example-margin mx-auto mb-5" color="primary" mode="indeterminate" value="50" />
  </div>

  <div class="text-content text-center mb-5">
    <div #ref><ng-content /></div>
    @if(!ref.children.length){
    <h4>{{ spinnerTitle() }}</h4>
    }
  </div>
</div>
