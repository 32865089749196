export interface ClubI {
  address1: string;
  address2: string;
  city: string;
  clubInRegionId?: null;
  clubLogo: string;
  clubName: string;
  note?: string;
  clubNoteId?: null;
  clubTeamsId?: null;
  commission: number;
  contactName: string;
  contactTitle?: string;
  createdAt: string;
  id: string;
  isClubACompany: false;
  lastAmount: number;
  locationId?: string;
  phoneNumber: string;
  emailAddress: string;
  signupUrl: null;
  state: string;
  uid?: string;
  updatedAt: string;
  zipCode: string;
  // address1: string;
  // address2: string;
  // zipCode: string;
  // city: null;
  // club_id: string;
  // id: string;
  // // club_name: string;
  // contact_name: null;
  // clubName: string;
  // contact_title: null;
  // country: null;
  // createdAt: string;
  // fax: null;
  // phone: null;
  // postal_code: null;
  // region: null;
  // state: string;
  // updatedAt: string;

  // id: string;
  // clubName: string;
  // address1: string;
  // address2: string;
  // city: string;
  // state: string;
  // zipCode: string;
  // phoneNumber: string;
  // uid: string | null;
  // lastAmount: number;
  // commission: number;
  // region: string;
  // company: boolean;
  // createdAt?: string;
  // updatedAt?: string;
  // location?: {
  //   latitude: number;
  //   longitude: number;
  // };
}

// export interface LocationI {
//   latitude: number;
//   longitude: number;
// }
// address1: '808 Borer Dale';
// address2: null;
// city: 'West Clementinabury';
// clubInRegionId: null;
// clubLogo: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/353.jpg';
// clubName: 'ClubGusikowski and Sons';
// clubNoteId: null;
// clubTeamsId: null;
// commission: '0';
// contactName: 'Trainer Roberta';
// createdAt: '2023-11-11T15:45:15.996Z';
// id: '5431840b-a102-4caf-b865-6dd199a5c92e';
// isClubACompany: false;
// lastAmount: '0';
// locationId: null;
// phoneNumber: '1-365-721-0282 x985';
// signupUrl: null;
// state: 'OR';
// uid: null;
// updatedAt: '2023-11-11T15:45:15.996Z';
// zipCode: '28808';
