import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { Router } from '@angular/router';

@Component({
  selector: 'bodyanalytics-login-button',
  templateUrl: './login-button.component.html',
})
export class LoginButtonComponent {
  protected loginLink = RouterUrlsEnum.LOGIN;

  protected auth = inject(AuthService);

  constructor(private router: Router) {}

  login() {
    this.router.navigate([`${this.loginLink}`]);
  }
}
