<footer class="py-3 my-4 border-top">
  <div class="container">
    <div class="row">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="col-md-4 d-flex align-items-center">
          <a
            class="navbar-brand d-flex align-items-center text-white text-decoration-none"
            href="https://www.appliedbiox.com/">
            <img src="./assets/images/ABX-logo-black.png" alt="logo image" class="logo" />
          </a>
          <span class="mb-3 mb-md-0 text-body-secondary">© 2024 AppliedBioX</span>
        </div>

        <ul class="nav col-md-6 justify-content-end">
           <li class="nav-item">
            <a href="https://calendar.appliedbiox.com/calendar/search" class="nav-link px-2 text-body-secondary"
              >Calendar</a
            >
          </li>

           <li class="nav-item">
            <a href="https://www.appliedbiox.com/faqs" class="nav-link px-2 text-body-secondary">FAQs</a>
          </li>

          <li class="nav-item">
            <a href="https://www.appliedbiox.com/mobile-fitness-solutions" class="nav-link px-2 text-body-secondary"
              >Mobile Fitness Solutions</a
            >
          </li>
          <li class="nav-item">
            <a href="https://www.appliedbiox.com/contact" class="nav-link px-2 text-body-secondary">Contact Us</a>
          </li>
          <!-- <li class="nav-item"><a href="https://www.appliedbiox.com/privacy-policy" class="nav-link px-2 text-body-secondary">Privacy Policy</a></li> -->
          <!-- <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">Pricing</a></li>
           <li class="nav-item"><a href="#" class="nav-link px-2 text-body-secondary">About</a></li> -->
        </ul>
      </div>
    </div>
  </div>
</footer>
