import { ProductI } from './products';

export interface CheckoutDataI{
  // selectedProduct: ProductI;
  selectedProductId:string;
  quantity: number;
  tax_state: string;
  serviceFeePriceId:string;
  productDefaultPriceId:string;
}
