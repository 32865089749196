import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amPm'
})
export class AmPmPipe implements PipeTransform {
  transform(value: string): string {
    // Split the input value based on the colon
    let [hours, minutes] = value.split(':');

    // Convert the hours part to a number for comparison
    const hoursInt = parseInt(hours, 10);

    // Determine whether it's AM or PM
    const amPm = hoursInt >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hoursInt % 12 === 0 ? '12' : (hoursInt % 12).toString().padStart(2, '0');

    // Return the formatted string
    return `${hours}:${minutes} ${amPm}`;
  }
}
