export interface UserI {
  fullName: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  role: string;
  homeGym: string;
  isEmailOptIn: boolean;
  userName: string;
  id: string;
  emailAddress: string;
  updatedAt: string;
  createdAt: string;
}
// isPushNotificationsEnabled: boolean;
// lastTimeSignedIn: string;
// photoURL: null;

// {
//   "id": "cfa8aee7-bf74-45a7-96be-ab0d35e40dfc",
//   "fullName": "Toby Cronin",
//   "firstName": "Abigale",
//   "lastName": "Friesen",
//   "emailAddress": "Doug82@hotmail.com",
//   "phoneNumber": "687.832.0401 x88580",
//   "password": "passw0rd123",
//   "role": "ADMIN",
//   "createdAt": "2023-11-11T15:45:14.759Z",
//   "updatedAt": "2023-11-11T15:45:14.759Z",
//   "userProfileId": null
// },
