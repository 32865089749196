<section>
  <div class="row">
    <div class="col-md-12">
      <div class="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
        <!-- Basic info -->
        <h1 class="h2 pt-xl-1 pb-3"><i class="bx bx-user-circle"></i> Create an account</h1>
        <bodyanalytics-register-account-form (submitFormEvent)="update($event)"></bodyanalytics-register-account-form>
      </div>
    </div>
  </div>
</section>
