export interface ClientHistoryI {
  date: string;
  height: number;
  weight: number;
  rmr: number;
  leanLb: number;
  fatLb: number;
  waterLb: number;
  bodyFatPercent: number;
  fatPercentChange: number;
  weightChange: number;
  leanLbChange: number;
  fatLbChange: number;
  fatPercentDiff: number;
}
