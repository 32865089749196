import { TestingEventI } from './testing-events';

export interface SignupSetupI {
  slug: string;
  description: string;
  creatorUserId: string;
  // creatorUserId: string;
  signupAvailabilityId?: string;
}
export interface BreakTimesI {
  id: string;
  startTime: string;
  endTime: string;
  signupAvailabilityId?: string;
  createdDate: string;
  updateDate: string;
}
export interface SignupFormI {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  dob: string; // Date
  sex: string;
  gender: string;
  address1: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  ethnicity: string;
  race: string;
  isRemindBySms:boolean;
  isRemindByEmail:boolean;
}
export interface SignupI {
  id: string;
  // signup_id: string;
  // testingevent_signups_id: string;
  slot_signup_id: string;
  customer_signup_id: string;
  testingEvent: TestingEventI;
  // emailAddress: string;
  // firstName: string;
  // lastName: string;
  // phoneNumber: string;
  // dob: string;
  // gender: string;
  // confirm_email: string;
  // testEventDetails: Event;
  // reminderOption: Reminder;
}
export interface Event {
  eventName: string;
  eventDate: string;
}

export interface Reminder {
  isRemindOptionEmail: boolean;
  isRemindOptionSms: boolean;
}
