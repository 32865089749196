<form [formGroup]="genericFormGroup" (ngSubmit)="onSubmit()">
  <div *ngFor="let field of fields">
    <label>{{ field.label }}</label>
    <input
      class="form-control"
      *ngIf="field.type === 'text' || field.type === 'email' || field.type === 'password'"
      type="text"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }"
      formControlName="{{ field.key }}"
      [matTooltip]="field.tooltip || ''"
      [matTooltipPosition]="field.tooltipPosition || 'below'"
      placeholder="{{ field.placeholder }}" />
    <input
      class="form-control"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }"
      *ngIf="field.type === 'date'"
      type="date"
      formControlName="{{ field.key }}" />
    <input
      class="form-control"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }"
      *ngIf="field.type === 'time'"
      type="time"
      formControlName="{{ field.key }}" />
    <input
      class="form-control"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }"
      *ngIf="field.type === 'number'"
      type="number"
      formControlName="{{ field.key }}" />

    <textarea
      class="form-control"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }"
      *ngIf="field.type === 'textarea'"
      formControlName="{{ field.key }}"
      placeholder="{{ field.placeholder || '' }}"></textarea>
    <select
      class="form-select"
      *ngIf="field.type === 'select'"
      formControlName="{{ field.key }}"
      [ngClass]="{ 'is-invalid': isFormSubmitted && genericFormGroup.get(field.key)!.errors }">
      <option *ngFor="let option of field.options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
    <div *ngIf="field.type === 'radio'" formArrayName="{{ field.key }}">
      <div *ngFor="let option of field.options; let i = index">
        <label [for]="field.key + i">
          <input
            type="radio"
            [formControlName]="field.key + i"
            [name]="field.key"
            [id]="field.key + i"
            [value]="option.value" />
          <!-- {{ option.label }} -->
          {{ option.label }}</label
        >
        <!-- Corresponding label for each radio button -->
      </div>
    </div>

    <div class="form-check-input" *ngIf="field.type === 'checkbox'" formArrayName="{{ field.key }}">
      <div *ngFor="let option of field.options; let i = index">
        <label class="form-check-label" [for]="field.key + i">
          <input class="form-check-input" [value]="option.value" />
          {{ option.label }}
        </label>
      </div>
    </div>
    <!-- <div class="invalid-feedback mt-5" *ngIf="field.validation?.required"> -->
    <div *ngIf="isFormSubmitted && genericFormGroup.get(field.key)!.errors">
      <div *ngIf="genericFormGroup.get(field.key)?.errors?.['required']">
        <small *ngIf="genericFormGroup.get(field.key)?.errors?.['required']" class="text-danger"
          >Field is required</small
        >
      </div>
    </div>
  </div>
  <div class="py-5">
    <button class="btn btn-primary" type="submit">Submit</button>
  </div>
</form>
