import { Injectable } from '@angular/core';
import { Subject, Observable, startWith, switchMap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimeoutService {
  private timeoutSubject = new Subject();
  private timeout$ = this.timeoutSubject.asObservable();

  constructor() {
    this.watchUserActivity();
  }

  watchUserActivity() {
    const activityEvents = ['mousemove', 'keydown', 'wheel'];
    activityEvents.forEach(event => document.addEventListener(event, () => this.resetTimer()));
  }

  resetTimer() {
    this.timeoutSubject.next(null);
  }

  startTimer(): Observable<number> {
    return this.timeout$.pipe(
      startWith(0),
      switchMap(() => timer(5 * 60 * 1000)), // 5 minutes
    );
  }
}
